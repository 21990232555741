(function () {
  const env = "staging";
  const graphDomain = env == "prod" ? "stent.io" : (env == "staging" || env == "dev") ? "staging.stent.io" : "dev.stent.io:5000";
  const apiDomain = env == "prod" ? "stent.io" : (env == "staging" || env == "dev") ? "staging.stent.io" : "dev.stent.io:5010";
  const authDomain = (env == "prod") ? "stent.io" : (env == "staging" || env == "dev") ? "staging.stent.io" : "dev.stent.io:5050";
  const protocol = (env == "prod" || env == "staging" || env == "dev") ? "https://" : "http://";
  const useFaker = false;

  let locale = "en-US";
  if (window.navigator && window.navigator.languages && window.navigator.languages.length > 0) {
    locale = window.navigator.languages[0];
  }

  window["stent"] = {
    version: {
      release: "1.2025.7",
      build: "20250213171040"
    },
    locale: locale,
    log: env == "prod" ? false : true,
    authentication: {
      client: "Eg3icS4Vj1r6zr4rDFenOh4BOV6YBEzlCstKbDIAKisAMCL1GGZBRuJ5+0E27nzH",
      supportBearer: protocol + "auth." + authDomain + "/zendesk/chat/auth",
      loginUrl: protocol + "auth." + authDomain + "/login"
    },
    api: {
      graphQL: protocol + "graph." + graphDomain + "/graphql",
      rest: protocol + "graph." + graphDomain + "/rest/v1",
      api: (useFaker && env === "dev") ? "http://localhost:9002/graphql" : protocol + "api." + apiDomain,
      auth: protocol + "auth." + authDomain
    },
    tenant: {
      key: "",
      name: "",
      timezone: ""
    },
    identity: {
      lastName: "",
      firstName: "",
      email: "",
      id: ""
    },
    env: (env == "prod" || env == "staging") ? "prod" : "dev",
    breakpoints: {
      sentimentAnalysis: 0.5
    }
  };
})();

stent.ajax = (function () {

  var getGraphQL = function (graphQuery, callback, callbackError, forceUrl) {
    var query = {
      query: graphQuery
    };
    var httpHeaders = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + stent.auth.getBearer(),
      "X-Workspace-ID": stent.tenant.key
    };
    var graphRequest = {
      method: "POST",
      headers: new Headers(httpHeaders),
      mode: "cors",
      cache: "default",
      body: JSON.stringify(query)
    };
    processCallbackRequest((forceUrl ? forceUrl : stent.api.graphQL), graphRequest, callback, callbackError);
  };

  var getRest = function (path, callback, callbackError, forceUrl) {
    var httpHeaders = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + stent.auth.getBearer(),
      "X-Workspace-ID": stent.tenant.key
    };
    var restApiRequest = {
      method: "GET",
      headers: new Headers(httpHeaders),
      mode: "cors",
      cache: "default"
    };
    processCallbackRequest((forceUrl ? forceUrl : stent.api.rest) + path, restApiRequest, callback, callbackError);
  };

  var postRest = function (path, body, callback, callbackError, forceUrl) {
    var httpHeaders = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + stent.auth.getBearer(),
      "X-Workspace-ID": stent.tenant.key
    };
    var restApiRequest = {
      method: "POST",
      headers: new Headers(httpHeaders),
      mode: "cors",
      cache: "default",
      body: JSON.stringify(body)
    };
    processCallbackRequest((forceUrl ? forceUrl : stent.api.rest) + path, restApiRequest, callback, callbackError);
  };

  var putRest = function (path, body, callback, callbackError, forceUrl) {
    var httpHeaders = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + stent.auth.getBearer(),
      "X-Workspace-ID": stent.tenant.key
    };
    var restApiRequest = {
      method: "PUT",
      headers: new Headers(httpHeaders),
      mode: "cors",
      cache: "default",
      body: body ? JSON.stringify(body) : ""
    };
    processCallbackRequest((forceUrl ? forceUrl : stent.api.rest) + path, restApiRequest, callback, callbackError);
  };

  var patchRest = function (path, body, callback, callbackError, forceUrl) {
    var httpHeaders = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + stent.auth.getBearer(),
      "X-Workspace-ID": stent.tenant.key
    };
    var restApiRequest = {
      method: "PATCH",
      headers: new Headers(httpHeaders),
      mode: "cors",
      cache: "default",
      body: body ? JSON.stringify(body) : ""
    };
    processCallbackRequest((forceUrl ? forceUrl : stent.api.rest) + path, restApiRequest, callback, callbackError);
  };

  var deleteRest = function (path, body, callback, callbackError, forceUrl) {
    var httpHeaders = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + stent.auth.getBearer(),
      "X-Workspace-ID": stent.tenant.key
    };
    var restApiRequest = {
      method: "DELETE",
      headers: new Headers(httpHeaders),
      mode: "cors",
      cache: "default",
      body: JSON.stringify(body)
    };
    processCallbackRequest((forceUrl ? forceUrl : stent.api.rest) + path, restApiRequest, callback, callbackError);
  };

  var processCallbackError = function (statusCode, error, callbackError) {
    if (typeof callbackError === "function") {
      callbackError(error);
      return;
    }
    // Set error Message globally
    stent.error = error;
    switch (statusCode) {
      case 401:
        stent.ui.loadError({ fileToLoad: "/errors/401.html" });
        break;
      case 404:
        stent.ui.loadError({ fileToLoad: "/errors/404.html" });
        break;
      case 500:
        stent.ui.loadError({ fileToLoad: "/errors/500.html" });
        break;
      default:
        stent.ui.loadError({ fileToLoad: "/errors/500.html" });
    }
  };

  var processCallbackRequest = async function (path, restApiRequest, callback, callbackError) {
    // check bearer expires date validity
    if (stent.auth.isBearerExpired() === true) {
      let refreshTokenRequest = await stent.auth.refreshToken();
      if (!refreshTokenRequest.ok || !refreshTokenRequest.message || !refreshTokenRequest.message.refresh_token || !refreshTokenRequest.message.access_token) {
        stent.auth.cleanCookiesAndLocalStorage();
        stent.auth.redirectToAuthPage();
        return;
      }
    }
    fetch(path, restApiRequest)
      .then(function (res) {
        switch (res.status) {
          case 401:
            processCallbackError(401, res.text(), callbackError);
            return null;
          case 404:
            processCallbackError(404, res.text(), callbackError);
            return null;
          case 500:
            processCallbackError(500, res.text(), callbackError);
            return null;
          case 200:
            return res.json();
          case 204:
            return null;
          default:
            return res.json();
        }
      })
      .then(function (res) {
        if (res !== null) {
          try {
            if (typeof callback === "function") callback(res);
          } catch (error) {
            console.log(res);
            console.log(error);
          }
        }

      })
      .catch(function (err) {
        console.log(err);
        try {
          if (typeof callbackError === "function") callbackError(err);
          else {
            processCallbackError(null, err);
          }
        } catch (error) {
          console.log(error);
        }
      });
  };


  /* ########################### */
  /* ASYNC AWAIT METHODS
  /* ########################### */

  // API (GRAPH QL)
  var getApiAsync = async function (graphQuery, method, url, releasePath) {
    var query = {
      query: graphQuery
    };
    var httpHeaders = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + stent.auth.getBearer(),
      "X-Workspace-ID": stent.tenant.key
    };
    var graphRequest = {
      method: method ? method : "POST",
      headers: new Headers(httpHeaders),
      mode: "cors",
      cache: "default",
      body: JSON.stringify(query)
    };
    return await processApiRequestAsync(graphRequest, url, releasePath);
  };

  var processApiRequestAsync = async (graphRequest, url, releasePath) => {
    // check bearer expires date validity
    if (stent.auth.isBearerExpired() === true) {
      let refreshTokenRequest = await stent.auth.refreshToken();
      if (!refreshTokenRequest.ok || !refreshTokenRequest.message || !refreshTokenRequest.message.refresh_token || !refreshTokenRequest.message.access_token) {
        stent.auth.cleanCookiesAndLocalStorage();
        stent.auth.redirectToAuthPage();
        return;
      }
    }
    try {
      const response = await fetch(url ? url : stent.api.api + (releasePath ? "/" + releasePath : ""), graphRequest);
      let jsonResponse = await response.json();
      switch (response.status) {
        case 401:
        case 404:
        case 500:
          return {
            ok: false,
            message: jsonResponse
          };
        case 204:
          return null;
        case 200:
          return {
            ok: true,
            message: jsonResponse
          };
        default:
          return jsonResponse;
      }
    } catch (err) {
      return err;
    }
  };

  // GRAPH QL
  var getGraphQLAsync = async function (graphQuery, releasePath) {
    var query = {
      query: graphQuery
    };
    var httpHeaders = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + stent.auth.getBearer(),
      "X-Workspace-ID": stent.tenant.key
    };
    var graphRequest = {
      method: "POST",
      headers: new Headers(httpHeaders),
      mode: "cors",
      cache: "default",
      body: JSON.stringify(query)
    };
    return await processGraphQLRequestAsync(graphRequest, releasePath);
  };

  var processGraphQLRequestAsync = async (graphRequest, releasePath) => {
    // check bearer expires date validity
    if (stent.auth.isBearerExpired() === true) {
      let refreshTokenRequest = await stent.auth.refreshToken();
      if (!refreshTokenRequest.ok || !refreshTokenRequest.message || !refreshTokenRequest.message.refresh_token || !refreshTokenRequest.message.access_token) {
        stent.auth.cleanCookiesAndLocalStorage();
        stent.auth.redirectToAuthPage();
        return;
      }
    }
    try {
      const response = await fetch(stent.api.graphQL + (releasePath ? "/" + releasePath : ""), graphRequest);
      let jsonResponse = await response.json();
      switch (response.status) {
        case 401:
        case 404:
        case 500:
          return {
            ok: false,
            message: jsonResponse
          };
        case 204:
          return null;
        case 200:
          return {
            ok: true,
            message: jsonResponse
          };
        default:
          return jsonResponse;
      }
    } catch (err) {
      return err;
    }
  };

  // REST
  const getRestAsync = async (path, forceUrl, forceToken) => {

    var httpHeaders = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + (typeof forceToken !== "undefined" ? forceToken : stent.auth.getBearer()),
      "X-Workspace-ID": stent.tenant.key
    };

    var restApiRequest = {
      method: "GET",
      headers: new Headers(httpHeaders),
      mode: "cors",
      cache: "default"
    };
    return await processRestRequestAsync(path, restApiRequest, forceUrl);
  };

  const postRestAsync = async (path, body, forceUrl) => {
    var httpHeaders = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + stent.auth.getBearer(),
      "X-Workspace-ID": stent.tenant.key
    };
    var restApiRequest = {
      method: "POST",
      headers: new Headers(httpHeaders),
      mode: "cors",
      cache: "default",
      body: JSON.stringify(body)
    };
    return await processRestRequestAsync(path, restApiRequest, forceUrl);
  };

  const putRestAsync = async (path, body, forceUrl) => {
    var httpHeaders = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + stent.auth.getBearer(),
      "X-Workspace-ID": stent.tenant.key
    };
    var restApiRequest = {
      method: "PUT",
      headers: new Headers(httpHeaders),
      mode: "cors",
      cache: "default",
      body: body ? JSON.stringify(body) : ""
    };
    return await processRestRequestAsync(path, restApiRequest, forceUrl);
  };

  const patchRestAsync = async (path, body, forceUrl) => {
    var httpHeaders = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + stent.auth.getBearer(),
      "X-Workspace-ID": stent.tenant.key
    };
    var restApiRequest = {
      method: "PATCH",
      headers: new Headers(httpHeaders),
      mode: "cors",
      cache: "default",
      body: body ? JSON.stringify(body) : ""
    };
    return await processRestRequestAsync(path, restApiRequest, forceUrl);
  };

  const deleteRestAsync = async (path, body, forceUrl) => {
    var httpHeaders = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + stent.auth.getBearer(),
      "X-Workspace-ID": stent.tenant.key
    };
    var restApiRequest = {
      method: "DELETE",
      headers: new Headers(httpHeaders),
      mode: "cors",
      cache: "default",
      body: JSON.stringify(body)
    };
    return await processRestRequestAsync(path, restApiRequest, forceUrl);
  };

  var processRestRequestAsync = async (path, restApiRequest, forceUrl) => {
    // check bearer expires date validity
    if (stent.auth.isBearerExpired() === true) {
      let refreshTokenRequest = await stent.auth.refreshToken();
      if (!refreshTokenRequest.ok || !refreshTokenRequest.message || !refreshTokenRequest.message.refresh_token || !refreshTokenRequest.message.access_token) {
        stent.auth.cleanCookiesAndLocalStorage();
        stent.auth.redirectToAuthPage();
        return;
      }
    }
    try {
      const response = await fetch((forceUrl ? forceUrl : stent.api.rest) + path, restApiRequest);
      let jsonResponse = await response.json();
      switch (response.status) {
        case 401:
        case 404:
        case 500:
          return {
            ok: false,
            message: jsonResponse
          };
        case 204:
          return null;
        case 200:
          return {
            ok: true,
            message: jsonResponse
          };
        default:
          return jsonResponse;
      }
    } catch (err) {
      return err;
    }
  };

  // AUTH
  const postAuthAsync = async (path, body) => {
    var httpHeaders = {
      "Content-Type": "application/json",
      "X-Workspace-ID": stent.tenant.key
    };
    var authApiRequest = {
      method: "POST",
      headers: new Headers(httpHeaders),
      mode: "cors",
      cache: "default",
      credentials: "include",
      body: JSON.stringify(body)
    };
    return await processAuthRequestAsync(path, authApiRequest);
  };

  var processAuthRequestAsync = async (path, authApiRequest) => {
    // check bearer expires date validity
    if (stent.auth.isBearerExpired() === true) {
      let refreshTokenRequest = await stent.auth.refreshToken();
      if (!refreshTokenRequest.ok || !refreshTokenRequest.message || !refreshTokenRequest.message.refresh_token || !refreshTokenRequest.message.access_token) {
        stent.auth.cleanCookiesAndLocalStorage();
        stent.auth.redirectToAuthPage();
        return;
      }
    }
    try {
      const response = await fetch(stent.api.auth + path, authApiRequest);
      let jsonResponse = await response.json();
      switch (response.status) {
        case 401:
        case 404:
        case 500:
          return {
            ok: false,
            message: jsonResponse
          };
        case 204:
          return null;
        case 200:
          return {
            ok: true,
            message: jsonResponse
          };
        default:
          return jsonResponse;
      }
    } catch (err) {
      return err;
    }
  };

  return {
    getGraphQL,
    getGraphQLAsync,

    getApiAsync,

    getRest,
    getRestAsync,
    putRest,
    putRestAsync,
    postRest,
    postRestAsync,
    deleteRest,
    deleteRestAsync,
    patchRest,
    patchRestAsync,

    postAuthAsync
  };
})();
stent.auth = (function () {
  let _roles = [];
  let _wildcard = false;

  var _hostname = window.location.hostname.split(".");
  var _cookiePrefix = _hostname.length > 3 ? _hostname[1] + "." :  "";

  _hostname.shift();
  _hostname = "." + _hostname.join(".");

  const getHostname = function () {
    return _hostname;
  };

  const isWilcard = function () {
    return _wildcard;
  };

  const getBearer = function () {
    return stent.utils.getCookie(`${_cookiePrefix}stnt.idtoken`);
  };

  const getRefreshToken = function () {
    return stent.utils.getCookie(`${_cookiePrefix}stnt.refreshtoken`);
  };

  const getUserIdentityKey = function () {
    if (
      stent.user &&
      stent.user.identities &&
      stent.user.identities["http://schemas.stent.io/identity/key"] &&
      Array.isArray(stent.user.identities["http://schemas.stent.io/identity/key"]) &&
      stent.user.identities["http://schemas.stent.io/identity/key"].length > 0
    ) {
      return stent.user.identities["http://schemas.stent.io/identity/key"][0];
    }
    return null;
  };

  const doRefreshToken = async function () {
    return await stent.ajax.postAuthAsync("/passwordless/refresh", {
      refresh_token: getRefreshToken(),
      client_id: stent.authentication.client,
    });
  };

  const getUserProfile = async () => {
    const url = stent.api.api;
    const method = "POST";
    let bearer = "Bearer " + getBearer();
    const contentType = "application/json";

    const query = {
      query: `query {
        userContext {
          user {
            id
            email
            firstName
            lastName
            pictureUrl
          }
        }
      }`,
    };

    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": contentType,
          Authorization: bearer,
        },
        body: JSON.stringify(query),
      });

      if (response.status === 200) {
        let jsonResponse = await response.json();
        let out = {
          ok: true,
          message: jsonResponse,
        };
        return out;
      } else {
        let errorMessage = await response.text();
        let out = {
          ok: false,
          error: {
            status: response.status,
            method: "getUserProfile",
            message: errorMessage,
          },
        };
        return out;
      }
    } catch (errorMessage) {
      let out = {
        ok: false,
        error: {
          status: "N/A",
          method: "getUserProfile",
          message: errorMessage,
        },
      };
      return out;
    }
  };

  const cleanCookiesAndLocalStorage = function () {

    localStorage.removeItem(`${_cookiePrefix}stnt.idtoken`);
    localStorage.removeItem(`${_cookiePrefix}stnt.refreshtoken`);

    var domainName = ".stent.io";
    var cookieNameOld = "aat." + window.location.hostname;
    document.cookie = cookieNameOld + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; domain=" + _hostname;

    var cookieName = `${_cookiePrefix}stnt.idtoken`;
    document.cookie = cookieName + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; domain=" + domainName;

    cookieName = `${_cookiePrefix}stnt.refreshtoken`;
    document.cookie = cookieName + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; domain=" + domainName;
  };

  const isBearerExpired = function () {
    let bearer = getBearer();

    if (!bearer) {
      return false;
    }

    let decodedBearer = stent.utils.parseJwt(bearer);
    let expireTimestamp = decodedBearer.exp ? decodedBearer.exp * 1000 : 0;
    if (new Date().getTime() > expireTimestamp) {
      return true;
    }

    return false;
  };

  const getRoles = function () {
    return _roles;
  };

  var redirectToAuthPage = function () {
    document.location.href =
      stent.authentication.loginUrl +
      "?client=" +
      encodeURIComponent(stent.authentication.client) +
      "&redirectUrl=" +
      window.location.href;
  };

  var getTenantData = async function () {

    /*eslint-disable */
    var query = `
      query {
        workspaceContext {
          workspace {
            features {
              name
              properties
            }
            organization {
              state
            }
            id
            company {
                name
                logoUrl
            }
            locale{
                timezone
            }
          }
        }
      }`
    /*eslint-enable */
    let result = await stent.ajax.getApiAsync(query, "POST");

    if (result.toString().includes("Failed to fetch")) {
      stent.ui.loadError({ fileToLoad: "/errors/500.html" });
      $(".navbar").addClass("d-none");
      $(".main-content").css("margin-left", 0);
      return false;
    }

    if (
      result &&
      result.ok === true &&
      result.message &&
      result.message.data &&
      result.message.data.workspaceContext &&
      result.message.data.workspaceContext.workspace
    ) {

      let workspaceObject = result.message.data.workspaceContext.workspace;
      let tenantObject = {
        key: workspaceObject.id,
        name: workspaceObject.company && workspaceObject.company.name ? workspaceObject.company.name : null,
        logo: workspaceObject.company && workspaceObject.company.logoUrl ? workspaceObject.company.logoUrl : null,
        timezone: workspaceObject.locale && workspaceObject.locale.timezone ? workspaceObject.locale.timezone : null,
        organization: workspaceObject.organization ? workspaceObject.organization : null,
        features: workspaceObject.features ? workspaceObject.features : null
      };

      stent.tenant = {
        ...stent.tenant,
        ...tenantObject,
      };

      stent.utils.log(stent.tenant);
    } else {
      // user is not in a tenant => Redirect to no-tenant error page
      stent.ui.loadError({ fileToLoad: "/errors/no-tenant.html" });
      $(".navbar").addClass("d-none");
      $(".main-content").css("margin-left", 0);
      return false;
    }

    return true;
  };

  var init = async function () {

    let bearer = getBearer();
    let refreshToken = getRefreshToken();

    if (
      (bearer === "null" || bearer === null || bearer === "" || typeof bearer === "undefined") &&
      (refreshToken === "null" || refreshToken === null || refreshToken === "" || typeof refreshToken === "undefined")
    ) {
      cleanCookiesAndLocalStorage();
      redirectToAuthPage();
      return;
    } else {
      // If the bearer is bad AND we have a refresh token
      if (
        (bearer === "null" || bearer === null || bearer === "" || typeof bearer === "undefined" || isBearerExpired()) &&
        refreshToken
      ) {
        // Regenerate the token
        let refreshTokenRequest = await doRefreshToken();

        // Error when try to refresh the token
        if (
          !refreshTokenRequest.ok ||
          !refreshTokenRequest.message ||
          !refreshTokenRequest.message.refresh_token ||
          !refreshTokenRequest.message.access_token
        ) {
          cleanCookiesAndLocalStorage();
          redirectToAuthPage();
          return;
        }

        // IMPORTANT !
        // Cookies are setted the the response Header of the doRefreshToken request

        // Set the variables with the updated values
        bearer = getBearer();
        refreshToken = getRefreshToken();
      }
    }

    // Set tenant key
    stent.tenant.key = document.location.pathname.split("/")[1];

    let jwt = stent.utils.parseJwt(getBearer());

    if (jwt["http://schemas.stent.io/identity/claims/tenants"] === "*") {
      _wildcard = true;
    }

    stent.user = {};

    let fetchUserIdentity = await getUserProfile();

    if (
      fetchUserIdentity.ok === true &&
      fetchUserIdentity.message &&
      fetchUserIdentity.message.data &&
      fetchUserIdentity.message.data.userContext &&
      fetchUserIdentity.message.data.userContext.user
    ) {
      stent.user.id = fetchUserIdentity.message.data.userContext.user.id
        ? fetchUserIdentity.message.data.userContext.user.id
        : null;

      stent.user.email = fetchUserIdentity.message.data.userContext.user.email
        ? fetchUserIdentity.message.data.userContext.user.email
        : null;

      stent.user.firstName = fetchUserIdentity.message.data.userContext.user.firstName
        ? fetchUserIdentity.message.data.userContext.user.firstName
        : null;

      stent.user.lastName = fetchUserIdentity.message.data.userContext.user.lastName
        ? fetchUserIdentity.message.data.userContext.user.lastName
        : null;

      stent.user.pictureUrl = fetchUserIdentity.message.data.userContext.user.pictureUrl
        ? fetchUserIdentity.message.data.userContext.user.pictureUrl
        : null;

      // Manage identities
      stent.user.identities = {};

      // Loop on JWT properties to find identities
      for (let prop in jwt) {
        if (prop.includes("http://schemas.stent.io/identity/")) {
          // Push always an array of identity Keys, not a string if only one identity available
          if (prop === "http://schemas.stent.io/identity/key" && typeof jwt[prop] === "string") {
            stent.user.identities[prop] = [jwt[prop]];
          } else {
            stent.user.identities[prop] = jwt[prop];
          }
        }
      }
    }

    stent.utils.log(stent.user);

    if (window["logInSentry"]) {
      // Sentry user object sent with error
      let sentryUserObject = { ...stent.user };
      delete sentryUserObject.pictureUrl;
      Sentry.setUser(sentryUserObject);
    }

    if (jwt.role && jwt.role === "customer:admin") {
      _roles.push(jwt.role);
    }

    if (stent.tenant.key) {
      return await getTenantData();
    }

    return true;
  };

  return {
    getBearer,
    getRefreshToken,
    getRoles,
    getHostname,
    getUserIdentityKey,
    isBearerExpired,
    redirectToAuthPage,
    cleanCookiesAndLocalStorage,
    isWilcard,
    init,
    refreshToken: doRefreshToken,
  };
})();

stent.ui = (function () {
  var sidebarSelector = "#sidebar";
  var contentSelector = ".main-content";
  var _isBannerVisible = false;

  var loadError = function (item) {
    loadInFrame(item);
  };

  var load = function (item) {

    stent.loader.show();

    // remove iframe class if needed
    if ($(contentSelector).hasClass("iframe")) {
      $(contentSelector).removeClass("iframe");
    }

    let url = new URL(window.location.origin + (item.fileToLoad.substring(0, 1) == "/" ? "" : "/") + item.fileToLoad);
    let qsVersion = "v=" + stent.version.release + "." + stent.version.build;

    if (!url.pathname.includes(".html")) item.fileToLoad = url.pathname + ".html";

    // Add Query String version params + existing params
    if (url.search) {
      url.search += "&" + qsVersion;
    } else {
      url.search += qsVersion;
    }

    item.fileToLoad += url.search;

    $.when(
      $.get({
        url: "/pages" + (item.fileToLoad.substring(0, 1) == "/" ? "" : "/") + item.fileToLoad
      }).then(
        function (data) {
          $(item.selector ? item.selector : contentSelector).html(data);
        },

        function (err) {
          // 404
          if (err.status === 404) {
            stent.loader.hide();
            loadInFrame({
              fileToLoad: "errors/404.html",
              selector: contentSelector,
              isError: true
            });
          }
        }
      )
    ).then(function () {
      if ($.isFunction(item.callback)) item.callback();
    });
  };

  var loadInFrame = function (item) {
    if (!item.selector) {
      item.selector = contentSelector;
    }
    $(item.selector).html("<iframe class=\"ui-frame\" src=\" " + item.fileToLoad + "\">");
    $(contentSelector).addClass("iframe");
  };

  var pushState = function (fileToLoad, shouldBeLoadedInIframe, location) {
    window.history.pushState(
      {
        fileToLoad: fileToLoad,
        shouldBeLoadedInIframe: shouldBeLoadedInIframe
      },
      null,
      location
    );
  };

  var prepareLoad = function ($this, shouldPushState) {

    var fileToLoad, shouldBeLoadedInIframe, urlLocation, item;
    var pathname = window.location.pathname.split("/")[2];

    // $this can be null, because we loads the homepage
    // Or because the link of the page can't be found in the sidebar
    if ($this || pathname === "") {
      if (!$this) {
        $this = $($(".nav-item .ui-link")[0]);
        shouldPushState = true;
      }

      // Get file to load
      fileToLoad = $this.attr("href");
      if (!fileToLoad) {
        fileToLoad = $this.attr("data-href");
      }
      shouldBeLoadedInIframe = $this.hasClass("ui-link-iframe");
      urlLocation = $this.attr("data-location") ? $this.attr("data-location") : fileToLoad;

      // Save the page to Load in history
      if (shouldPushState === true) {
        pushState(fileToLoad, shouldBeLoadedInIframe, urlLocation.replace(".html", ""));
      }


    } else {
      // Get file to load
      fileToLoad = pathname;
      shouldBeLoadedInIframe = false;
      urlLocation = pathname;
    }

    item = {
      fileToLoad: fileToLoad,
      selector: contentSelector,
      location: urlLocation
    };

    // LoadFile in DOM
    if (!shouldBeLoadedInIframe) {
      load(item);
    } else {
      loadInFrame(item);
    }
  };

  var bindEvents = function () {
    $("body").on("click", ".ui-link", function (evt) {
      evt.preventDefault();
      prepareLoad($(this), true);
    });
  };

  var manageHistory = function () {
    window.onpopstate = function (event) {
      var fileToLoad = event.state.fileToLoad;
      var $menu =
        $("[href=\"" + fileToLoad + "\"]").length > 0
          ? $("[href=\"" + fileToLoad + "\"]")
          : $("[data-location=\"" + fileToLoad + "\"]").length > 0
            ? $("[data-location=\"" + fileToLoad + "\"]")
            : null;

      prepareLoad($menu, false);
    };
  };

  var loadNavigation = function () {
    load({
      fileToLoad: "sidebar.html",
      selector: sidebarSelector
    });
  };

  var initBanner = async function () {

    let fetchMessages = await stent.ajax.getRestAsync(
      "/tenants/" + stent.tenant.key + "/messages"
    );

    if (!fetchMessages.ok) {
      console.warn("STENT => Error when trying to get the workspace status messages.");
      return;
    }

    let _currentIndex = 0;
    let _countMessages = fetchMessages.message && fetchMessages.message.length ? fetchMessages.message.length : 0;

    let bindBannerEvents = function () {
      $("body")
        .off("click", ".previous-message")
        .on("click", ".previous-message", function () {
          if (_currentIndex === 0) {
            _currentIndex = _countMessages - 1;
          } else {
            _currentIndex--;
          }

          $(".a-stent-banner:first-child").animate({
            "margin-top": -(_currentIndex * 50),
          }, 100);

        });

      $("body")
        .off("click", ".next-message")
        .on("click", ".next-message", function () {
          if (_currentIndex + 1 === _countMessages) {
            _currentIndex = 0;
          } else {
            _currentIndex++;
          }

          $(".a-stent-banner:first-child").animate({
            "margin-top": -(_currentIndex * 50),
          }, 100);

        });
    };

    if (_countMessages > 0) {
      _isBannerVisible = true;


      if (_isBannerVisible) {

        let DOM = "<div id=\"stent-banner\">";

        /* eslint-disable */
        fetchMessages.message.forEach((message, index) => {
          DOM += `
            <div class="a-stent-banner severity-${message.severity ? message.severity : 'low'}">
              ${_countMessages > 1 ? `<span class="fe fe-arrow-left-circle mr-3 previous-message"></span>` : ``}
              <div class="banner-message">
                <h1>
                  ${message.url ? `<a target="_blank" href="${message.url}">` : ``
            }
                  ${_countMessages > 1 ? `${(index + 1)}/${_countMessages} -` : ``}
                  ${message.message}
                  ${message.url ? `</a>` : ``
            }
                </h1>
              </div>
              ${_countMessages > 1 ? `<span class="fe fe-arrow-right-circle ml-3 next-message"></span>` : ``}

            </div>`;
        });
        /* eslint-enable */

        DOM += "</div>";

        $("body").prepend(DOM);
        $("html").addClass("bannerVisible");

        if (fetchMessages.message.length > 1) {
          bindBannerEvents();
        }

      }


    }

  };

  // this methods is executed by the stent.navbar.js,
  // because the nav bar should be always executed before the content
  var loadFirstPage = function () {

    var pathname = window.location.pathname.split("/")[2];

    if (!pathname) {
      pathname = "home";
    }

    var $menu =
      $("[href=\"" + pathname + "\"]").length > 0
        ? $("[href=\"" + pathname + "\"]")
        : $("[data-location=\"" + pathname + "\"]").length > 0
          ? $("[data-location=\"" + pathname + "\"]")
          : null;

    pushState(window.location.href, false, window.location.href.replace(".html", ""));

    prepareLoad($menu, false);

  };

  var setPageTitle = function (title) {
    var _title = "Stent";

    if (title) {
      _title = title;
    }
    document.title = (stent.tenant && stent.tenant.name ? stent.tenant.name + " - " : "") + _title;
  };
  var init = async function () {
    $("body").removeClass("d-none");

    let tryAuth = await stent.auth.init();
    if (!tryAuth) {
      return;
    }

    stent.loader.show();
    loadNavigation();
    initBanner();
    bindEvents();
    manageHistory();
  };

  return {
    load,
    loadError,
    loadFirstPage,
    loadInFrame,
    setPageTitle,
    init,
    pushState,
    isBannerVisible: function () {
      return _isBannerVisible;
    }
  };
})();

stent.utils = {
  guid: function() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  },

  /* eslint-disable */
  isValidURL: function (url) {
    var expression = /([^\S]|^)(((https?\:\/\/)|(www\.))(\S+))/gi;
    var regex = new RegExp(expression);
    return !(url.match(regex) === null);
  },
  /* eslint-enable */

  /* eslint-disable */
  isEmailValid: function(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  },
  /* eslint-enable */

  /* eslint-disable */
  escapeJsonString: function (str) {
    if (!str) {
      return str;
    }
    return str
      .replace(/[\\]/g, "\\\\")
      .replace(/[\"]/g, "\\\"")
      .replace(/[\/]/g, "\\/")
      .replace(/[\b]/g, "\\b")
      .replace(/[\f]/g, "\\f")
      .replace(/[\n]/g, "\\n")
      .replace(/[\r]/g, "\\r")
      .replace(/[\t]/g, "\\t");
  },
  /* eslint-enable */

  randomIntFromInterval: function(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  },

  log: function(message) {
    if (stent.log) console.log(message);
  },

  arrayToObject: (array, keyField) =>
    array.reduce((obj, item) => {
      obj[item[keyField]] = item;
      return obj;
    }, {}),

  getURLParam: function(paramName) {
    if (paramName) {
      return new URL(window.location.href).searchParams.get(paramName);
    }
    return null;
  },

  getURLRemovedParam(paramName) {
    var rtn = window.location.href.split("?")[0],
      param,
      params_arr = [],
      queryString = window.location.href.indexOf("?") !== -1 ? window.location.href.split("?")[1] : "";
    if (queryString !== "") {
      params_arr = queryString.split("&");
      for (var i = params_arr.length - 1; i >= 0; i -= 1) {
        param = params_arr[i].split("=")[0];
        if (param === paramName) {
          params_arr.splice(i, 1);
        }
      }
      rtn = rtn + (params_arr.length > 0 ? "?" : "") + params_arr.join("&");
    }
    return rtn;
  },

  getURLWithUpdatedParam: function(paramName, val) {
    var TheAnchor = null;
    var newAdditionalURL = "";
    var tempArray = window.location.href.split("?");
    var baseURL = tempArray[0];
    var additionalURL = tempArray[1];
    var temp = "";

    if (additionalURL) {
      let tmpAnchor = additionalURL.split("#");
      let TheParams = tmpAnchor[0];
      TheAnchor = tmpAnchor[1];
      if (TheAnchor) additionalURL = TheParams;

      tempArray = additionalURL.split("&");

      for (let i = 0; i < tempArray.length; i++) {
        if (tempArray[i].split("=")[0] != paramName) {
          newAdditionalURL += temp + tempArray[i];
          temp = "&";
        }
      }
    } else {
      let tmpAnchor = baseURL.split("#");
      let TheParams = tmpAnchor[0];
      TheAnchor = tmpAnchor[1];

      if (TheParams) baseURL = TheParams;
    }

    if (TheAnchor) val += "#" + TheAnchor;

    let rows_txt = temp + "" + paramName + "=" + val;
    return baseURL + "?" + newAdditionalURL + rows_txt;
  },

  parseJwt: function(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function(c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  },

  getCookie: function(name) {
    var v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
    return v ? v[2] : null;
  },

  humanFileSize: function(bytes, si) {
    var thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
      return bytes + " B";
    }
    var units = si
      ? ["kB","MB","GB","TB","PB","EB","ZB","YB"]
      : ["KiB","MiB","GiB","TiB","PiB","EiB","ZiB","YiB"];
    var u = -1;
    do {
      bytes /= thresh;
      ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1)+" "+units[u];
  },

  generateInitialsLogo: function (name) {
    let initials = "..";
    let splitName = name.split(" ");
    if (splitName.length > 1) {
      initials = splitName[0].substring(0, 1).toUpperCase() + splitName[1].substring(0, 1).toUpperCase();
    } else if (name.length >= 2) {
      initials = name.substring(0, 2).toUpperCase();
    }
    return `<span title="${name}" style="background-color: ${stent.utils.stringToColour(initials)};" class="initials">${initials}</span>`;
  },

  stringToColour: function(input_str) {

    var baseRed = 128;
    var baseGreen = 128;
    var baseBlue = 128;

    //lazy seeded random hack to get values from 0 - 256
    //for seed just take bitwise XOR of first two chars
    var seed = input_str.charCodeAt(0) ^ input_str.charCodeAt(1);
    var rand_1 = Math.abs((Math.sin(seed++) * 10000)) % 256;
    var rand_2 = Math.abs((Math.sin(seed++) * 10000)) % 256;
    var rand_3 = Math.abs((Math.sin(seed++) * 10000)) % 256;

    //build colour
    var red = Math.round((rand_1 + baseRed) / 2);
    var green = Math.round((rand_2 + baseGreen) / 2);
    var blue = Math.round((rand_3 + baseBlue) / 2);

    //return { red: red, green: green, blue: blue };
    return `rgba(${red}, ${green}, ${blue}, 1)`;
  },

  popupCenter: function ({url, title, w, h, onClose}) {

    const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft;
    const top = (height - h) / 2 / systemZoom + dualScreenTop;
    const newWindow = window.open(url, title,
      `
      scrollbars=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
    );

    if (typeof onClose === "function") {
      var timer = setInterval(function() {
        if (newWindow.closed) {
          clearInterval(timer);
          onClose();
        }
      }, 1000);
    }

    if (window.focus) newWindow.focus();

  },

  arrayToHMLList: function (arr) {

    if (!arr) {
      return "";
    }

    let html = "";
    html += "<ul style='text-align: left; margin: 5px 0 0 0; padding: 0 15px;'>";
    arr.forEach((entry) => {
      html += "<li>" + entry + "</li>";
    });
    html += "</ul>";

    return html;
  },

  sortArrayOfObjectByPropertyName: function (property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a,b) {
      /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    };
  },

  capitalize: (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

};
stent.toast = (function() {

  var open = function (type, options) {

    var _o = {
      type: type ? type : "primary",
      message: typeof options === "string" ? options : (options && options.message) ? options.message : "Your message here...",
      autohide: (options && options.autohide === false) ? false : true,
      autohideDelay: (options && options.autohideDelay) ? options.autohideDelay : 3000,
      guid: "toast_" + stent.utils.guid()
    };

    $("#stent-toast-wrapper").append(`
      <div class="alert alert-${_o.type} alert-dismissible fade show" role="alert" id="${_o.guid}">
        ${_o.message}
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>`
    );

    if (_o.autohide) {
      setTimeout(function () {
        if ($("#" + _o.guid).length > 0) {
          $("#" + _o.guid).slideUp(200, function () {
            $(this).remove();
          });
        }
      }, _o.autohideDelay);
    }

  };

  var primary = function (options) {
    open("primary", options);
  };

  var secondary = function (options) {
    open("secondary", options);
  };

  var success = function (options) {
    open("success", options);
  };

  var danger = function (options) {
    open("danger", options);
  };

  var warning = function (options) {
    open("warning", options);
  };

  var info = function (options) {
    open("info", options);
  };

  var light = function (options) {
    open("light", options);
  };

  var dark = function (options) {
    open("light", options);
  };

  var closeAll = function () {
    $("#stent-toast-wrapper").empty();
  };

  var init = function () {
    if ($("#stent-toast-wrapper").length === 0) {
      $(".main-content").after("<div id=\"stent-toast-wrapper\"></div>");
    }
  };

  init();

  return {
    primary,
    secondary,
    success,
    danger,
    warning,
    info,
    light,
    dark,
    closeAll
  };
})();

stent.loader = (function () {

  var show = function (elem, zIndex, options = {}) {
    // The elem param should be a jQuery object, but if the user
    // gives a string, we transform the string into a jQuery object.
    var _elem = (typeof elem === "string") ? $(elem) : elem;

    var _$elem = _elem ? _elem : $(".main-content");

    _$elem.append(DOM(zIndex, options));
  };

  var DOM = function (zIndex, options) {
    let classes = "ui-loader";
    if (options.transparent) {
      classes += " transparent-background"
    }
    /* eslint-disable */
    return `
      <div class="${classes}" ${zIndex ? `style="z-index:${zIndex};"` : ""}>
        <div class="spinner-grow" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    `;
    /* eslint-enable */
  };

  var hideAll = function () {
    $(".ui-loader").remove();
  };

  var hide = function (elem) {

    var _elem = (typeof elem === "string") ? $(elem) : elem;

    if (!_elem) {
      hideAll();
    } else {
      _elem.find(".ui-loader").remove();
    }
  };

  return {
    show,
    hide
  };
})();

stent.libs = {
  navbar: {
    prod: {
      files: ["/assets/js/requires/stent.navbar.min.js"]
    },
    dev: {
      files: ["/assets/js/requires/stent.navbar.js"]
    }
  },
  widgets: {
    prod: {
      files: ["/assets/js/requires/stent.widgets.min.js"]
    },
    dev: {
      files: ["/assets/js/requires/stent.widgets.js"]
    }
  },
  quill: {
    prod: {
      files: ["/node_modules/quill/dist/quill.min.js", "/assets/js/requires/stent.quill.min.js"]
    },
    dev: {
      files: ["/node_modules/quill/dist/quill.min.js", "/assets/js/requires/stent.quill.js"]
    }
  },
  flatpickr: {
    prod: {
      files: ["/node_modules/flatpickr/dist/flatpickr.min.js", "/assets/js/requires/stent.flatpickr.min.js"]
    },
    dev: {
      files: ["/node_modules/flatpickr/dist/flatpickr.js", "/assets/js/requires/stent.flatpickr.js"]
    }
  },
  dropzone: {
    prod: {
      files: ["/node_modules/dropzone/dist/min/dropzone.min.js", "/assets/js/requires/stent.dropzone.min.js"]
    },
    dev: {
      files: ["/node_modules/dropzone/dist/min/dropzone.min.js", "/assets/js/requires/stent.dropzone.js"]
    }
  },
  popover: {
    prod: {
      files: ["/assets/js/requires/stent.popover.min.js"]
    },
    dev: {
      files: ["/assets/js/requires/stent.popover.js"]
    }
  },
  highlight: {
    prod: {
      files: ["/node_modules/highlightjs/highlight.pack.min.js", "/assets/js/requires/stent.highlight.min.js"]
    },
    dev: {
      files: ["/node_modules/highlightjs/highlight.pack.min.js", "/assets/js/requires/stent.highlight.js"]
    }
  },
  list: {
    prod: {
      files: ["/node_modules/list.js/dist/list.min.js", "/assets/js/requires/stent.list.min.js"]
    },
    dev: {
      files: ["/node_modules/list.js/dist/list.min.js", "/assets/js/requires/stent.list.js"]
    }
  },
  ace: {
    prod: {
      files: [
        "/node_modules/ace-builds/src-min/ace.js",
        "/node_modules/ace-builds/src-min/mode-javascript.js",
        "/node_modules/ace-builds/src-min/mode-aql.js",
        "/node_modules/ace-builds/src-min/theme-twilight.js"
      ]
    },
    dev: {
      files: [
        "/node_modules/ace-builds/src/ace.js",
        "/node_modules/ace-builds/src/mode-javascript.js",
        "/node_modules/ace-builds/src/mode-aql.js",
        "/node_modules/ace-builds/src/theme-tomorrow.js"
      ]
    }
  },
  chart: {
    prod: {
      files: [
        "/node_modules/chart.js/dist/Chart.min.js",
        "/assets/libs/chart.js/Chart.extension.js",
        "/assets/js/requires/stent.chart.min.js"
      ]
    },
    dev: {
      files: [
        "/node_modules/chart.js/dist/Chart.min.js",
        "/assets/libs/chart.js/Chart.extension.js",
        "/assets/js/requires/stent.chart.js"
      ]
    }
  },
  fullcalendar: {
    prod: {
      files: [
        "/node_modules/@fullcalendar/core/main.min.js",
        "/node_modules/@fullcalendar/interaction/main.min.js",
        "/node_modules/@fullcalendar/bootstrap/main.min.js",
        "/node_modules/@fullcalendar/daygrid/main.min.js",
        "/node_modules/@fullcalendar/timegrid/main.min.js",
        "/node_modules/@fullcalendar/list/main.min.js",
        "/node_modules/@fullcalendar/timeline/main.min.js"
      ]
    },
    dev: {
      files: [
        "/node_modules/@fullcalendar/core/main.js",
        "/node_modules/@fullcalendar/interaction/main.js",
        "/node_modules/@fullcalendar/bootstrap/main.js",
        "/node_modules/@fullcalendar/daygrid/main.js",
        "/node_modules/@fullcalendar/timegrid/main.js",
        "/node_modules/@fullcalendar/list/main.js",
        "/node_modules/@fullcalendar/timeline/main.js"
      ]
    }
  },
  draggable: {
    prod: {
      files: [
        "/node_modules/draggabilly/dist/draggabilly.pkgd.min.js"
      ]
    },
    dev: {
      files: [
        "/node_modules/draggabilly/dist/draggabilly.pkgd.min.js"
      ]
    }
  },
  slider: {
    prod: {
      files: [
        "/node_modules/nouislider/distribute/nouislider.min.js"
      ]
    },
    dev: {
      files: [
        "/node_modules/nouislider/distribute/nouislider.min.js"
      ]
    }
  },
  qr: {
    prod: {
      files: ["/assets/js/requires/stent.qr.min.js"]
    },
    dev: {
      files: ["/assets/js/requires/stent.qr.js"]
    }
  }
};

stent.loadedLibraries = [];

stent.requireJS = function(libsName, callback) {
  // Check if libsName is an array or a string
  if (!(libsName != null && (typeof libsName == "string" || typeof libsName == "object"))) return false;

  // Check if libsName is a string. If yes, transform it to an array
  var libs = typeof libsName == "string" ? [libsName] : libsName;

  var loadScript = function(libName, scriptURL) {

    var script = document.createElement("script");
    script.type = "application/javascript";
    script.src = scriptURL;
    document.getElementsByTagName("head")[0].appendChild(script);

    // Important success and error for the promise
    script.onload = function() {
      onLoaded(libName, scriptURL);
    };
    script.onerror = function() {};

  };

  var initLibs = function() {
    for (var i = 0; i < libs.length; i++) {
      var lib = stent[libs[i]];
      if (lib && lib.init) {
        lib.init();
      }
    }
  };

  // Build an array of files URL to load
  var filesToLoad = [];

  for (var i = 0; i < libs.length; i++) {
    var libName = libs[i];
    if (stent.libs[libName] && stent.libs[libName][stent.env].files && stent.loadedLibraries.indexOf(libName) === -1) {
      for (var j = 0; j < stent.libs[libName][stent.env].files.length; j++) {
        var scriptURL = stent.libs[libName][stent.env].files[j];
        filesToLoad.push({ libName: libName, scriptURL: scriptURL });
      }
    }
  }

  var index = 0;
  if (filesToLoad.length > 0) {
    loadScript(filesToLoad[0].libName, filesToLoad[0].scriptURL + "?v=" + stent.version.release + "." + stent.version.build);
  } else {
    initLibs();
    if (typeof callback === "function") {
      callback();
    }
  }

  var onLoaded = function(libName) {

    index++;
    stent.loadedLibraries.push(libName);

    if (index < filesToLoad.length) {
      loadScript(filesToLoad[index].libName, filesToLoad[index].scriptURL + "?v=" + stent.version.release + "." + stent.version.build);
    } else {
      initLibs();
      if (typeof callback === "function") {
        callback();
      }
    }
  };
};

const UPLOADCARE_PUBLIC_KEY_FINDERS = "5d0111e44c53efdb8afe"; // eslint-disable-line
const UPLOADCARE_PUBLIC_KEY_ARTICLE = "0aea304e6a7511e15107"; // eslint-disable-line
const UPLOADCARE_PUBLIC_KEY_MEDIAS = "86551d98c48352e2665b"; // eslint-disable-line
const UPLOADCARE_PUBLIC_KEY_AVATARS = "2a0b225afd14b14348bc"; // eslint-disable-line
const UPLOADCARE_PUBLIC_KEY_PERSONA_PDF = "27266414a5b089ce1546"; // eslint-disable-line

stent.contact = (function () {

  let _contact = {};

  let _deelContracts = null;
  let _deelLookups = null;
  let _employmentCountryGuide = null;

  let _contractValidator = null;

  let _campaign = null;
  let _currentLane = null;
  let _addToNewLane = null;

  let _activityForm = {};

  let _wrapper = $("#contactModal .modal-content");

  // API functions

  const getSignals = function (key) {
    return stent.ajax.getRestAsync("/tenants/" + stent.tenant.key + "/contacts/" + key + "/signals");
  };

  const getContact = function (key) {
    let queryParams = `?tenantKey=${stent.tenant.key}`;
    if (_campaign) {
      queryParams += `&campaignName=${_campaign}`;
    }
    return stent.ajax.getRestAsync(`/profiles/${key}${queryParams}`);
  };

  const addNote = function (newNoteText) {
    if (!_contact.profile?._key) return;

    const body = {
      content: newNoteText,
      userKey: stent.user?.id,
      tenantKey: stent.tenant.key,
    }
    return stent.ajax.postRestAsync(`/profiles/${_contact.profile._key}/notes`, body);
  }

  const fetchDeelContracts = async function () {
    // Fetch contracts from back-end
    const { profile: { firstName, lastName } = {} } = _contact || {};
    const fullName = firstName && lastName
      ? `${firstName} ${lastName}`
      : "";
    const queryParams = `?fullName=${fullName}`;
    const fetchContractsResponse = await stent.ajax.getRestAsync(`/deel/tenants/${stent.tenant.key}/contracts${queryParams}`);

    // Set contracts object
    if (fetchContractsResponse.ok && fetchContractsResponse.message) {
      _deelContracts = fetchContractsResponse.message;
    } else {
      stent.toast.danger("There was an error loading contracts.");
    }
  }

  const fetchDeelFormLookups = async function () {
    // Fetch form lookups from back-end
    const fetchLookupsResponse = await stent.ajax.getRestAsync(`/deel/tenants/${stent.tenant.key}/lookups`);

    // Set lookups object
    if (fetchLookupsResponse.ok && fetchLookupsResponse.message) {
      _deelLookups = fetchLookupsResponse.message;
    } else {
      stent.toast.danger("There was an error loading deel lookups.");
    }
  }

  const isDeelAppEnabled = async function () {
    let query = `
      query {
        workspaceContext {
          appById(id: "1024627676") {
            enabled
          }
        }
      }
    `;

    let result = await stent.ajax.getApiAsync(query, "POST");

    return result?.message?.data?.workspaceContext?.appById?.enabled;
  }

  const getDeelData = async function () {
    // Reset container content
    $("#contact-deel-container").html(`
      <div id="deel-contracts-container" class="col"></div>
      <div id="deel-form-container" class="col"></div>
    `);
    stent.loader.show("#contact-deel-container", 100, { transparent: true });

    const isEnabled = await isDeelAppEnabled();
    if (!isEnabled) {
      const appsUrl = `${window.location.origin}/${stent.tenant.key}/apps`;
      // Inform user that the Deel app is not connected
      $("#contact-deel-container").append(`
        <div id="deel-app-not-connected-container" class="row">
          <div class="col">
            <h3>Deel app is not connected</h3>
            <p>Please visit the <a target="_blank" href="${appsUrl}">Apps Page</a> to connect to Deel with your API key.</p>

            <button id="refresh-deel-tab-button" class="btn btn-primary">Refresh Tab</button>
          </div>
        </div>
      `);
      $("#refresh-deel-tab-button").on("click", getDeelData);
      stent.loader.hide();
      return;
    }

    await Promise.all([
      fetchDeelContracts(),
      fetchDeelFormLookups(),
    ]);

    updateDeelContainer();

    stent.loader.hide();
  };

  const fetchDeelCountryGuide = async function (countryCode) {
    stent.loader.show("#create-contract-form");

    // Fetch employment country guide from back-end
    const fetchCountryGuideResponse = await stent.ajax.getRestAsync(`/deel/tenants/${stent.tenant.key}/countries/${countryCode}/guide`);

    // Handle corresponding form fields
    if (fetchCountryGuideResponse.ok && fetchCountryGuideResponse.message) {
      _employmentCountryGuide = fetchCountryGuideResponse.message;

      const {
        currency,
        salary: { min: minSalary, max: maxSalary } = {},
      } = _employmentCountryGuide || {};

      if (currency) {
        $("#compensation-currency").val(currency)
      }

      if (_contractValidator && Number.isInteger(minSalary) && Number.isInteger(maxSalary)) {
        $("#compensation-salary").rules("add", {
          min: minSalary,
          max: maxSalary,
          messages: {
            min: jQuery.validator.format("The minimum salary for selected country is ${0}"),
            max: jQuery.validator.format("The maximum salary for selected country is ${0}"),
          },
        });
      } else {
        $("#compensation-salary").rules("remove", "min max");
      }

      updateProbationPeriod();

      updateFormHealthInsuranceProviders();
    } else {
      stent.toast.danger("There was an error loading deel lookups.");
    }

    stent.loader.hide();
  }

  const postDeelContract = async function (contractForm) {
    stent.loader.show($("#create-contract-form").parent().parent());

    // Post contract to back-end
    const postContractResponse = await stent.ajax.postRestAsync(
      `/deel/tenants/${stent.tenant.key}/contracts`,
      contractForm,
    );

    // Update contracts list
    if (postContractResponse && postContractResponse.ok && postContractResponse.message?.id) {
      stent.loader.hide();

      if (_contractValidator) {
        _contractValidator.destroy();
      }
      updateDeelForm();

      stent.loader.show("#deel-contracts-list-container");

      await fetchDeelContracts();
      updateDeelContractsList();
    } else if (postContractResponse && postContractResponse.deelValidationErrors) {
      const validationErrorsDisplay = postContractResponse.deelValidationErrors.map((error, index) => `<br>${index + 1} - ${error}`);
      stent.toast.danger({ message: "Please correct the following and try again: " + validationErrorsDisplay, autohideDelay: 30 });
    } else {
      stent.toast.danger("There was an error while creating contract.");
    }

    stent.loader.hide();
  };

  // Update DOM functions

  const updateProbationPeriod = function () {
    const {
      probation: { min: minProbation, max: maxProbation } = {},
      partTimeProbation: { min: minPartTimeProbation, max: maxPartTimeProbation } = {},
    } = _employmentCountryGuide || {};

    if (_contractValidator && Number.isInteger(minProbation) && Number.isInteger(maxProbation)) {
      const employmentType = $("#employment-type").val();

      $("#probation-period").rules("add", {
        min: employmentType === "Part-time" ? minPartTimeProbation : minProbation,
        max: employmentType === "Part-time" ? maxPartTimeProbation : maxProbation,
        required: true,
        messages: {
          min: jQuery.validator.format("The minimum probation period for selected country is {0} days"),
          max: jQuery.validator.format("The maximum probation period for selected country is {0} days"),
        },
      });

      // Set probation period to maximum valid number of days
      $("#probation-period").val(employmentType === "Part-time" ? maxPartTimeProbation : maxProbation);
    } else {
      $("#probation-period").rules("remove", "min max required");
      _contractValidator.element($("#probation-period"));
    }
  }

  const updateFormHealthInsuranceProviders = function () {
    const { healthInsurance } = _employmentCountryGuide || {};

    if (healthInsurance && healthInsurance.status?.toUpperCase() === "REQUIRED") {
      const { providers } = healthInsurance;
      if (!providers || providers.length < 1) return;

      const providerOptions = providers
        .map(provider => `<option>${provider.name}</option>`)
        .join("");

      const healthInsuranceFormGroups = `
        <div class="form-group col-4 d-flex flex-column">
          <label for="health-insurance-provider">Provider</label>
          <select id="health-insurance-provider" name="healthInsuranceProvider" class="form-control">
            ${providerOptions}
          </select>
        </div>

        <div class="form-group col-4 d-flex flex-column">
          <label for="health-insurance-plan">Plan</label>
          <select id="health-insurance-plan" name="healthInsurancePlan" class="form-control">

          </select>
        </div>
      `;

      $("#contract-form-health-insurance-container .form-row").html(healthInsuranceFormGroups);
      $("#contract-form-health-insurance-container").removeClass("d-none");

      const selectedProviderName = providers[0].name;
      // Update the available plans for first provider
      updateFormHealthInsurancePlans(selectedProviderName);

      $("#health-insurance-provider").rules("add", {
        required: true,
        messages: "Health insurance provider is required",
      });
    } else {
      $("#contract-form-health-insurance-container .form-row").html("");
      $("#contract-form-health-insurance-container").addClass("d-none");
    }
  }

  const updateFormHealthInsurancePlans = function (selectedProvider) {
    const { healthInsurance: { providers } = {} } = _employmentCountryGuide || {};
    if (!providers) return;

    const matchingProvider = providers.find(provider => provider.name === selectedProvider);
    const { plans } = matchingProvider || {};
    if (!plans || plans.length < 1) return;

    const planOptions = plans
      .map(plan => `<option value="${plan.id}">${plan.name}</option>`)
      .join("");

    $("#health-insurance-plan").html(planOptions);

    $("#health-insurance-plan").rules("add", {
      required: true,
      messages: "Health insurance plan is required",
    });
  }

  // Build reply intent badge switch the intent key from signals
  const getIntent = function (key) {
    let html = "";
    switch (key) {
      case "none":
        html = `<span class="badge badge-warning mb-2 text-white" style="cursor: help;" data-toggle="tooltip" data-placement="right" title="A situation where an automated response will not be ideal or human intervention is needed or recommended.">Other</span>`
        break;

      case "not_interested":
        html = `<span class="badge badge-danger mb-2" style="cursor: help;" data-toggle="tooltip" data-placement="right" title="Candidate is NOT interested but does not really leave the door open. Rude not interested.">NOT interested</span>`

        break;

      case "more_info":
        html = `<span class="badge badge-success mb-2" style="cursor: help;" data-toggle="tooltip" data-placement="right" title="Candidate is interested and requests for more information.">Interested / More information</span>`

        break;

      case "meet":
        html = `<span class="badge badge-success mb-2" style="cursor: help;" data-toggle="tooltip" data-placement="right" title="Candidate is Interested and requested a time to meet/talk.">Interested / Meet</span>`

        break;

      case "meet_more_info":
        html = `<span class="badge badge-success mb-2" style="cursor: help;" data-toggle="tooltip" data-placement="right" title="Candidate is interested, requested more information and also a time to meet/talk.">Interested / More information / Meet</span>`

        break;

      case "network_info":
        html = `<span class="badge badge-info mb-2" style="cursor: help;" data-toggle="tooltip" data-placement="right" title="Candidate is NOT interested but nice. Nice and leaves the window open, or offers to help refer, not interested. (door slightly open)">NOT interested but Nice</span>`
        break;

      default:
        break;
    }

    return html
  }

  const signalsDOM = function () {
    var html = "";

    // Build HTML
    if (typeof _contact.signals === "string") {
      html += _contact.signals;
    } else {
      /* eslint-disable */
      _contact.signals.forEach(signal => {

        html += `
          <div class="row">
            <div class="col-auto">
              <div class="signal-vertical-bar"></div>
              <div class="signal-type mx-auto bg-${signal.level}" data-toggle="tooltip" data-placement="right" title="${signal.type}">
                <span class="fe fe-${signal.icon}"></span>
              </div>
            </div>

            <div class="col">

              <div class="card">
                <div class="card-body">

                  ${signal.intent !== null ? getIntent(signal.intent) : ''}

                  <p style="white-space: pre-wrap;">${signal.body}</p>
                  <time class="small text-muted">
                    ${moment(signal.timestamp).format("MM/DD/YYYY LT")}
                    ${signal.ownerName || signal.origin ? ` - ` : ``}
                    ${signal.ownerName ? `<span>${signal.ownerName}</span>` : ``}
                    ${signal.origin ? ` - <span style="white-space: break-spaces;" class="badge badge-soft-secondary">${signal.origin}</span>` : ``}
                  </time>
                </div>
              </div>

            </div>

          </div>`;
      });
      /* eslint-enable */
    }


    return html;
  };

  const getActivityIcon = function (type) {
    switch (type) {
      case "call":
        return "phone-outgoing";
      case "meeting":
        return "users";
      case "email":
        return "mail";
      default:
        return null;
    }
  }

  const activitiesDOM = function () {
    const activities = _contact.activities;
    if (activities && activities.length > 0) {
      const activitiesList = activities
        .map(activity => {
          const icon = getActivityIcon(activity.type);
          const { owner } = activity;
          const ownerName = owner && owner.firstName && owner.lastName
            ? `- ${owner.firstName} ${owner.lastName}`
            : "";
          return `
            <div class="row">
              <div class="col-auto">
                <div class="signal-vertical-bar"></div>
                <div class="signal-type mx-auto bg-primary" data-toggle="tooltip" data-placement="right" title="${activity.type}">
                  <span class="fe fe-${icon}"></span>
                </div>
              </div>

              <div class="col">

                <div class="card">
                  <div class="card-body">
                    <p style="white-space: pre-wrap;">${activity.content}</p>
                    <time class="small text-muted">
                      ${moment(activity.startDate).format("lll")}
                      ${activity.endDate ? `- ${moment(activity.endDate).format("lll")}` : ""}
                      ${ownerName}
                    </time>
                  </div>
                </div>

              </div>

            </div>
          `;
        })
        .join("");
      return `${activitiesList}`
    } else {
      return `<div class="row">
        <div class="col">
          <span>No activities yet</span>
        </div>
      </div>`
    }
  }

  const setDeelFormStates = function (selectId, countryCode) {
    const {
      demographics: {
        resides: {
          location: {
            address: {
              administrativeAreaLevel1: { shortName: contactStateCode } = {},
            } = {}
          } = {},
        } = {},
      } = {},
    } = _contact.profile || {};

    const { countries = [] } = _deelLookups || {};
    const matchingCountry = countries.find(country => country.code === countryCode);
    if (!matchingCountry) return;

    const { stateType, states } = matchingCountry;
    let stateOptions;
    if (stateType === null) {
      stateOptions = `<option selected disabled value="">Not Applicable</option>`;
    } else if (states && states.length > 0) {
      stateOptions = states
        .map(state => `<option value="${state.code}" ${contactStateCode === state.code ? "selected" : ""}>${state.name}</option>`)
        .join("");
    } else {
      stateOptions = `<option selected disabled value="">No state available</option>`;
    }

    $(`#${selectId}`).html(stateOptions);

    if (stateType) {
      const capitalizedStateType = stateType.charAt(0).toUpperCase()
        + stateType.slice(1)
      $(`#${selectId}`).siblings("label").html(capitalizedStateType);

      $("#employment-state").rules("add", {
        required: true,
        messages: {
          required: `Employment ${stateType} is required`,
        }
      });
    } else {
      $("#employment-state").rules("remove");
    }
  }

  const updateDeelContractsList = function () {
    $("#deel-contracts-list-container").html(`<h3>Current contracts</h3>`);

    const contractsList = _deelContracts && _deelContracts.length > 0
      ? `
      <ul id="deel-contracts-list">
        ${_deelContracts
        .map(contract => {
          const statusDisplay = contract
            .status
            .split("_")
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
          return `<li class="mb-2">
            <a target="_blank" href="${`https://app.deel.com/contract/${contract.id}/overview`}">${contract.title}</a> - ${statusDisplay}
          </li>`;
        })
        .join("")}
      </ul>
    `
      : "<span>No contracts yet</span>";

    $("#deel-contracts-list-container").append(contractsList);
  }

  const updateDeelForm = function () {
    const {
      firstName,
      lastName,
      email,
      demographics: {
        resides: {
          location: {
            address: {
              country: { shortName: countryCode } = {},
              locality: { longName: city } = {},
            } = {}
          } = {},
        } = {},
      } = {},
    } = _contact.profile || {};

    const { countries, currencies, seniorityLevels } = _deelLookups || {};

    const countryOptions = countries
      ? countries
        .map(country => `<option value="${country.code}" ${countryCode === country.code ? "selected" : ""}>${country.name}</option>`)
        .join("")
      : `<option>Could not fetch countries</option>`;

    const seniorityOptions = seniorityLevels
      ? seniorityLevels
        .map(seniorityLevel => `<option value="${seniorityLevel.id}">${seniorityLevel.name}</option>`)
        .join("")
      : `<option>Could not fetch seniority levels</option>`;

    const currencyOptions = currencies
      ? currencies
        .map(currency => `<option value="${currency.code}">${currency.code}</option>`)
        .join("")
      : `<option>Could not fetch currencies</option>`;

    const employmentTypeOptions = `
      <option>Full-time</option>
      <option>Part-time</option>
    `;

    const contractForm = `
      <form id="create-contract-form" class="mt-4 position-relative d-none">
        <h3>Employee</h3>
        <div class="form-row">
          <div class="form-group col-4 d-flex flex-column">
            <label for="employee-first-name">First Name</label>
            <input type="text" id="employee-first-name" name="employeeFirstName" class="form-control" value="${firstName || ""}" />
          </div>

          <div class="form-group col-4 d-flex flex-column">
            <label for="employee-last-name">Last Name</label>
            <input type="text" id="employee-last-name" name="employeeLastName" class="form-control" value="${lastName || ""}" />
          </div>

          <div class="form-group col-4 d-flex flex-column">
            <label for="employee-email">Email</label>
            <input type="email" id="employee-email" name="employeeEmail" class="form-control" value="${email || ""}"/>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-6 d-flex flex-column">
            <label for="employee-country">Nationality</label>
            <select id="employee-country" name="employeeCountry" class="form-control">
              ${countryOptions}
            </select>
          </div>
        </div>

        <div class="form-group">
          <label for="employee-address">Address</label>
          <input type="text" class="form-control" id="employee-address" name="employeeStreet">
        </div>

        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="employee-city">City</label>
            <input type="text" class="form-control" id="employee-city" name="employeeCity" value="${city || ""}">
          </div>
          <div class="form-group col-md-4">
            <label for="employee-state">State</label>
            <select id="employee-state" name="employeeState" name="employee-state" class="form-control">
            </select>
          </div>
          <div class="form-group col-md-2">
            <label for="employee-zip">Zip</label>
            <input type="text" class="form-control" id="employee-zip" name="employeeZip">
          </div>
        </div>

        <h3>Employment</h3>
        <div class="form-row">
          <div class="form-group col-6">
            <label for="employment-country">Country</label>
            <select id="employment-country" name="employmentCountry" class="form-control">
              ${countryOptions}
            </select>
          </div>

          <div class="form-group col-6">
            <label for="employment-state">State</label>
            <select id="employment-state" name="employmentState" class="form-control">
            </select>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-6 d-flex flex-column">
            <label for="employment-type">Type</label>
            <select id="employment-type" name="employmentType" class="form-control">
              ${employmentTypeOptions}
            </select>
          </div>

          <div class="form-group col-6 d-flex flex-column">
            <label for="probation-period">Probation period (days)</label>
            <input type="number" id="probation-period" name="probationPeriod" class="form-control" min="0">
          </div>
        </div>

        <div class="form-group">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="employment-visa" name="employmentWorkVisaRequired">
            <label class="form-check-label" for="employment-visa">
              Work visa required
            </label>
          </div>
        </div>

        <div class="form-row">
          <div id="employment-start-date-container" class="form-group col-6 position-relative">
            <label for="employment-start-date">Start Date</label>
            <div type="text" id="employment-start-date" class="form-control">
              <span id="employment-start-date-display">Select Date</span>
            </div>
            <input type="text" id="employment-start-date-input" name="employmentStartDate" class="form-control">
          </div>

          <div class="form-group col-6">
            <label for="job-title">Job title</label>
            <input type="text" class="form-control" id="job-title" name="jobTitle">
          </div>
        </div>

        <div class="form-group">
          <label for="scope-of-work">Scope of work</label>
          <textarea
            class="form-control"
            id="scope-of-work"
            name="scopeOfWork"
            rows="2"
            placeholder="Enter list of employee's tasks and obligations, activities they will have to perform in the company. Must be detailed."
          ></textarea>
        </div>

        <h3>Seniority</h3>
        <div class="form-row">
          <div class="form-group col-12 d-flex flex-column">
            <label for="seniority-level">Seniority Level</label>
            <select id="seniority-level" name="seniorityLevel" class="form-control">
              ${seniorityOptions}
            </select>
          </div>
        </div>

        <h3>Compensation</h3>
        <div class="form-row">
          <div class="form-group col-4 d-flex flex-column">
            <label for="compensation-currency">Salary Currency</label>
            <select id="compensation-currency" name="compensationCurrency" class="form-control">
              ${currencyOptions}
            </select>
          </div>

          <div class="form-group col-4 d-flex flex-column">
            <label for="compensation-salary">Annual Salary</label>
            <input type="number" min="1" id="compensation-salary" name="compensationSalary" class="form-control" />
          </div>
        </div>

        <div id="contract-form-health-insurance-container" class="d-none">
          <h3>Health Insurance</h3>
          <div class="form-row">
          </div>
        </div>

        <div class="mt-2 d-flex justify-content-start">
          <button type="submit" class="btn btn-primary position-relative">
            Create Contract
          </button>

          <button id="cancel-deel-contract-button" type="button" class="btn btn-secondary position-relative ml-3">
            Cancel
          </button>
        </div>
      </form>
    `

    const deelFormHtml = `
      <div class="row mt-4">
        <div class="col">
          <button id="deel-create-contract-button" class="btn btn-primary">Create New Contract</button>
        </div>
      </div>
      <div class="row pb-3">
        <div class="col">
          ${contractForm}
        </div>
      </div>
    `;

    // Add form to tab container
    $("#deel-form-container").html(deelFormHtml);

    deelContractFormValidation();

    setDeelContractFormDatePickers();

    if (countryCode) {
      setDeelFormStates("employee-state", countryCode);
      setDeelFormStates("employment-state", countryCode);
      fetchDeelCountryGuide(countryCode);
    }
  }

  const updateDeelContainer = function () {
    const contractsHtml = `
      <div class="row">
        <div id="deel-contracts-list-container" class="col position-relative">
        </div>
      </div>
    `;

    // Add contracts html to tab container
    $("#deel-contracts-container").html(contractsHtml);

    updateDeelContractsList();

    updateDeelForm();
  }

  const createProfileWidgetContent = function (widget) {
    return `
      <h5 class="header-pretitle">
        ${widget.icon ? `<span class="fe fe-${widget.icon} mr-2" style="font-size: 1rem;"></span>` : ""}
        ${widget.title}
      </h5>
      <div class="card flex-grow-1">
        <div class="card-body">
          ${widget.content}
        </div>
      </div>
    `;
  }

  const profileDOM = function () {
    const emptyValue = "-";

    // RELATIONS
    const relationsContent = _contact.profile?.relationals?.network?.connections || emptyValue;

    // LINKEDIN
    const linkedInUsername = _contact.profile?.linkedin;
    const linkedInContent = linkedInUsername
      ? `<a target="_blank" href="https://www.linkedin.com/in/${linkedInUsername}/">${linkedInUsername}</a>`
      : emptyValue;

    // TWITTER
    const twitterHandle = _contact.profile?.twitter;
    const twitterContent = twitterHandle && twitterHandle.length > 0
      ? twitterHandle.join(", ")
      : emptyValue;

    // STATUS DROPDOWN
    let statusSelect = "";
    if (_currentLane) {
      const addDefaultOption = _currentLane && ["invited", "connected", "nurtured", "engaged"].includes(_currentLane.name);

      statusSelect = `<select id="contact-status-select" class="card-body form-control">`;
      const canAddToNewLane = !!_campaign;
      if (!canAddToNewLane || addDefaultOption) {
        statusSelect += `<option class="option" value="${_currentLane.name}" selected disabled>${_currentLane.title}</option>`
      }
      // Prevent adding to new lane if no campaign or program is selected
      if (canAddToNewLane) {
        statusSelect += `
              <option class="option" value="interested" ${_currentLane.name === "interested" ? "selected" : ""}>Interested</option>
              <option class="option" value="contacted" ${_currentLane.name === "contacted" ? "selected" : ""}>Contacted</option>
              <option class="option" value="interviewed" ${_currentLane.name === "interviewed" ? "selected" : ""}>Interviewed</option>
              <option class="option" value="chosen" ${_currentLane.name === "chosen" ? "selected" : ""}>Chosen</option>
              <option class="option" value="hired" ${_currentLane.name === "hired" ? "selected" : ""}>Hired</option>
            `
      }
      statusSelect += `</select>`;
    }

    let statusWidget = statusSelect
      ? `
        <div class="col-12 col-xl-4 text-center d-flex flex-column">
          <h5 class="header-pretitle">
            Status
          </h5>
          <div id="contact-status-select-wrapper" class="card flex-grow-1">
            ${statusSelect}
            <span class="fe fe-chevron-down"></span>
          </div>
        </div>
      `
      : "";

    // LANGUAGES
    const languages = _contact.profile?.demographics?.locale?.detectedLanguage;
    const languagesContent = languages && languages.length > 0
      ? languages.map(lang => lang.code).join(", ")
      : emptyValue;

    // LOCATION
    let locationContent = emptyValue;
    const location = _contact.profile?.demographics?.resides?.location;
    const profileHasCoordinates = location &&
      location.geo &&
      location.geo.latitude &&
      location.geo.longitude;
    if (location) {
      let city = location.address?.locality?.longName;
      let country = location.address?.country?.longName;

      if (city) {
        locationContent = `${city}${country ? `, ${country}` : ""}`;
      } else if (country) {
        locationContent = country;
      }

      if (profileHasCoordinates) {
        locationContent += `<span id="toggle-profile-map" class="fe fe-eye-off ml-2" style="font-size: 1rem; cursor: pointer;"></span>`;
      }
    }

    const profileWidgetsToShow = [
      { title: "Languages", content: languagesContent, icon: "twitter" },
      { title: "Relations", content: relationsContent, icon: "share-2" },
      { title: "LinkedIn", content: linkedInContent, icon: "linkedin" },
      { title: "Twitter", content: twitterContent, icon: "twitter" },
      { title: "Location", content: locationContent, icon: "map" },
    ];
    const profileWidgetsHtml = profileWidgetsToShow.map(widget => `
      <div class="col-12 col-lg-6 col-xl-4 text-center d-flex flex-column">
        ${createProfileWidgetContent(widget)}
      </div>
    `).join("");

    // GOOGLE MAPS
    const mapContainer = profileHasCoordinates
      ? `
        <div id="profile-map-outer-container" class="my-4 d-none">
          <div class="gmap_canvas">
            <iframe
              width="100%"
              height="400"
              src="https://maps.google.com/maps?q=${encodeURIComponent(location.geo.latitude)},${encodeURIComponent(location.geo.longitude)}&t=&z=8&ie=UTF8&iwloc=&output=embed"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0">
            </iframe>
          </div>
        </div>
      `
      : "";

    // GENDER
    const genderContent = _contact.profile?.demographics?.faceAttributes?.gender || emptyValue;

    // AGE
    const ageContent = _contact.profile?.demographics?.faceAttributes?.age || emptyValue;

    const hiddenProfileWidgets = [
      { title: "Gender", content: genderContent, icon: "eye" },
      { title: "Age", content: ageContent, icon: "gift" },
    ];

    const hiddenProfileWidgetsHtml = hiddenProfileWidgets.map(widget => `
      <div class="col-12 col-lg-6 col-xl-4 text-center d-flex flex-column">
        ${createProfileWidgetContent(widget)}
      </div>
    `).join("");

    // LATEST ACTIVITY
    let latestActivityContainer = "";
    if (_campaign) {
      let latestActivity = _contact.activities && _contact.activities.length > 0
        ? _contact.activities[0]
        : null;
      let latestActivityContent = "-";
      if (latestActivity) {
        const { content: activityContent, startDate } = latestActivity;
        const startDateDisplay = new Date(startDate)?.toLocaleDateString();

        latestActivityContent = `
          <span id="latest-activity-text">${activityContent}${startDateDisplay ? ` - ${startDateDisplay}` : ""}</span>
          <span class="fe fe-calendar mr-2" style="font-size: 1rem;"></span>
        `;
      }
      const lastActivityWidget = { title: "Last Activity", content: latestActivityContent, icon: null };

      latestActivityContainer = `
        <div class="d-flex flex-column mt-3">
          <div class="d-flex justify-content-between align-items-center mb-1">
            <h5 class="header-pretitle mb-0">${lastActivityWidget.title}</h5>
            <button id="add-activity-button" class="btn btn-outline-secondary py-2">Add</button>
          </div>
          <div class="card flex-grow-1 mb-2">
            <div class="card-body d-flex justify-content-between align-items-center p-3">
              ${lastActivityWidget.content}
            </div>
          </div>
          <form id="add-activity-form" class="mt-2 position-relative d-none">
            <div class="flex-grow-1 mr-2 d-flex flex-column">
              <input type="text" name="activity-content" class="form-control" />
              <div class="invalid-feedback">
                Please enter an activity content.
              </div>
            </div>
            <div class="d-flex mt-2">
              <div class="new-note-type py-2 px-3" data-type="call">
                <span class="fe fe-phone-outgoing mr-2"></span>
                <span>Call</span>
              </div>
              <div class="new-note-type py-2 px-3" data-type="meeting">
                <span class="fe fe-users mr-2"></span>
                <span>Meeting</span>
              </div>
              <div class="new-note-type py-2 px-3" data-type="email">
                <span class="fe fe-mail mr-2"></span>
                <span>Email</span>
              </div>
            </div>
            <div id="activity-dates-container" class="position-relative mt-2">
              <div class="mr-3">
                <div class="d-flex align-items-center">
                  <div id="activity-start-date" class="form-control">
                    <span id="activity-start-date-input">Start Date</span>
                  </div>
                  <span class="mx-3">-</span>
                  <div id="activity-end-date" class="form-control">
                    <span id="activity-end-date-input">End Date</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-2 d-flex justify-content-start">
              <button type="submit" class="btn btn-primary position-relative" disabled>
                Add Activity
              </button>
            </div>
          </form>
        </div>
      `;
    }

    // NOTES
    let notesContent = "";
    const contactNotes = _contact.notes;
    if (contactNotes && contactNotes.length > 0) {
      notesContent = contactNotes
        .map(note => `<span>${note.content.trim()}</span>`)
        .join("");
    }

    /* eslint-disable */
    return `
        <h5 class="header-pretitle">
          <span class="fe fe-file-text mr-2" style="font-size: 1rem;"></span>
          Summary
        </h5>
        <div class="card">
          <div class="card-body" style="white-space: pre-wrap;">
            ${_contact.profile && _contact.profile.summary ? _contact.profile.summary.trim() : "-"}
          </div>
        </div>

        <div class="row">
          ${statusWidget}
          ${profileWidgetsHtml}
        </div>

        ${mapContainer}

        <div class="row mb-4">
          <div class="col d-flex flex-column">
            <div class="d-flex flex-column">
              <h5 class="header-pretitle">Notes</h5>
              <div id="notes-card-container" class="card mb-1">
                <div class="card-body d-flex flex-column">${notesContent}</div>
              </div>
              <form id="add-note-form" class="form-inline mt-2">
                <div class="flex-grow-1 mr-2 d-flex flex-column">
                  <input type="text" name="note-content" class="form-control" />
                  <div class="invalid-feedback">
                    Please enter a note content.
                  </div>
                </div>
                <button type="submit" class="btn btn-primary position-relative" disabled>
                  Add Note
                </button>
              </form>
            </div>

            ${latestActivityContainer}
          </div>
        </div>

        <div id="profile-hidden-widgets-row" class="row d-none mt-4">
          ${hiddenProfileWidgetsHtml}
        </div>

        <div class="row mb-4">
          <div class="col">
            <button id="additional-profile-info-button" class="btn btn-secondary" data-status="hidden">
              <span class="fe fe-eye-off mr-2" style="font-size: 1rem;"></span>
              <span class="action mr-1">Show</span> additional profile info
            </button>
          </div>
        </div>
    `;
    /* eslint-enable */
  };

  const companiesDOM = function () {

    if (!_contact.companies || _contact.companies.length === 0) {
      return `
          <div class="alert alert-light" role="alert">
            Ooooops. No company found for this ambassador.
          </div>`;
    }

    return _contact.companies.map(company => {

      let name = company.name ? company.name : "";
      let initials = name.length > 2 ? name.substring(0, 2).toUpperCase() : "";
      let isCurrent = company.current === true ? true : false;
      let hasLogo = company.logo && company.logo !== "" ? true : false;
      let logo = hasLogo
        ? `<img src="${company.logo
        }" class="avatar-img rounded-circle" onerror="this.onerror=null;this.src='/assets/img/avatars/profiles/default-company.svg'" ></img>`
        : `<span class="avatar-title rounded-circle ${isCurrent ? "bg-primary" : ""}">${initials}</span>`;
      let description = company.description && company.description !== "" ? company.description : "";
      let title = company.title && company.title !== "" ? company.title : "";
      let website = company.website && company.website !== "" ? company.website : `https://www.google.com/search?q=${encodeURIComponent(name)}`;
      let locationName = company.locationName && company.locationName !== "" ? company.locationName : "";
      let employeeCountRange =
        company.firmographics &&
          company.firmographics.employeeCountRange &&
          company.firmographics.employeeCountRange &&
          company.firmographics.employeeCountRange.start &&
          company.firmographics.employeeCountRange.end
          ? company.firmographics.employeeCountRange.start +
          " - " +
          company.firmographics.employeeCountRange.end +
          " employees"
          : "";

      let startJobDate =
        company.timePeriod &&
          company.timePeriod.startDate &&
          company.timePeriod.startDate.month &&
          company.timePeriod.startDate.year
          ? moment(company.timePeriod.startDate.month, "M").format("MMMM") + " " + company.timePeriod.startDate.year
          : "";

      let endJobDate =
        company.timePeriod &&
          company.timePeriod.endDate &&
          company.timePeriod.endDate.month &&
          company.timePeriod.endDate.year
          ? moment(company.timePeriod.endDate.month, "M").format("MMMM") + " " + company.timePeriod.endDate.year
          : "";

      /* eslint-disable */
      return `
          <div class="row">

            <div class="col-auto">
              <div class="company-avatar">${logo}</div>
            </div>

            <div class="col">

              <div class="card ${!isCurrent ? "card-inactive" : ""}">
                <div class="card-body">
                  ${isCurrent ? `<span class="badge badge-pill badge-primary float-right">Current</span>` : ``}
                  <h2 class="mb-3">${name} <a style="font-size: 1rem;" class="fe fe-link ml-2" href="${website}" target="_blank"></a></h2>
                  <h4>${title}</h4>
                  ${startJobDate !== ""
          ? `<div class="small">
                          <span class="fe fe-calendar mr-2"></span>${startJobDate}${endJobDate !== "" ? " - " + endJobDate : " - Today"
          }
                        </div>`
          : ``
        }
                  ${locationName !== ""
          ? `<div class="small"><span class="fe fe-map mr-2"></span>${locationName}</div>`
          : ``
        }
                  ${employeeCountRange !== ""
          ? `<div class="small"><span class="fe fe-users mr-2"></span>${employeeCountRange}</div>`
          : ``
        }
                  ${description !== "" ? `<div style="white-space: pre-wrap;">${description}</div>` : ``}
                </div>
              </div>

            </div>

          </div>

        `;
      /* eslint-enable */
    }).join("");
  };

  const skillsDOM = function () {

    if (!_contact.skills || _contact.skills.length === 0) {
      return `
          <div class="alert alert-light" role="alert">
            Ooooops. No skill found for this ambassador.
          </div>`;
    }

    return _contact.skills.map(skill => {
      return `
          <div class="badge badge-pill badge-primary mr-2 mb-2" style="font-size: 1rem;">
            <span class="fe fe-check-circle mr-1"></span>
            ${skill.name ? skill.name : ""}
          </div>
        `;
    }).join("");
  };

  const isActivityFormValid = function () {
    return _activityForm.startDate &&
      _activityForm.startDate._isValid &&
      _activityForm.content &&
      _activityForm.type
  }

  const validateActivityForm = function () {
    const submitButton = $(`#add-activity-form button[type="submit"]`);
    if (isActivityFormValid()) {
      submitButton.prop("disabled", false);
    } else {
      submitButton.prop("disabled", true);
    }
  }

  const setActivityFormDatePickers = function () {
    $("#activity-start-date").daterangepicker({
      showDropdowns: true,
      alwaysShowCalendars: true,
      opens: "right",
      drops: "auto",
      timePicker: true,
      singleDatePicker: true,
      parentEl: "#activity-dates-container",
    });

    $("#activity-start-date").on("apply.daterangepicker", function (_event, picker) {
      const { startDate } = picker;
      _activityForm.startDate = startDate;
      validateActivityForm();

      $("#activity-end-date").data("daterangepicker").setStartDate(startDate);
      $("#activity-end-date").data("daterangepicker").setEndDate(startDate);
      const newStartDateLabel = startDate.format("lll");
      $("#activity-start-date-input").text(newStartDateLabel);
    });

    $("#activity-end-date").daterangepicker({
      showDropdowns: true,
      alwaysShowCalendars: true,
      startDate: _activityForm.startDate || moment(),
      opens: "right",
      drops: "auto",
      timePicker: true,
      singleDatePicker: true,
      parentEl: "#activity-dates-container",
      isInvalidDate: function (date) {
        return !_activityForm.startDate || (date < _activityForm.startDate)
      },
    });

    $("#activity-end-date").on("apply.daterangepicker", function (_event, picker) {
      const { startDate } = picker;
      _activityForm.endDate = startDate;

      const newStartDateLabel = startDate.format("lll");
      $("#activity-end-date-input").text(newStartDateLabel);
    });
  }

  const setDeelContractFormDatePickers = function () {
    $("#employment-start-date").daterangepicker({
      autoUpdateInput: false,
      showDropdowns: true,
      alwaysShowCalendars: true,
      startDate: moment(),
      opens: "right",
      drops: "auto",
      singleDatePicker: true,
      parentEl: "#employment-start-date-container",
    });

    $("#employment-start-date").on("apply.daterangepicker", function (_event, picker) {
      const { startDate } = picker;

      const newStartDateLabel = startDate.format("ll");
      $("#employment-start-date-display").text(newStartDateLabel);

      // Set hidden input's value
      $("#employment-start-date-input").val(startDate.valueOf());

      // Validate element
      _contractValidator.element("#employment-start-date-input");
    });
  }

  const open = async function (key, data = {}) {
    _campaign = data.campaign;
    _currentLane = data.currentLane;
    _addToNewLane = data.addToNewLane;

    $("#contactModal").modal("show");

    if (!key) return;

    clear();

    stent.loader.show("#contactModal .modal-content");

    // Get informations fo the contact
    let fetchContact = await getContact(key);

    if (fetchContact.ok && fetchContact.message && fetchContact.message.profile !== null) {
      _contact = { ...fetchContact.message };
      _wrapper.html(mainDOM());
    } else {
      _wrapper.html(`
        <div class="alert alert-warning alert-dismissible fade show" role="alert">
          <strong>Ooooooooops.</strong> Something went wrong when trying to get the contact data.
        </div>
      `);
    }

    _wrapper.append(loaderDOM());

    stent.loader.show("#contactModal #loader-wait-signals");

    let signals = await getSignals(key);

    $("#loader-wait-signals").remove();

    if (signals.ok && signals.message) {
      _contact.signals = [...signals.message];
    } else {
      _contact.signals = `
        <div class="alert alert-warning alert-dismissible fade show" role="alert">
          <strong>Ooooooooops.</strong> Something went wrong when trying to get the contact's journey.
        </div>
      `;
    }

    _wrapper.append(tabsContainerDOM());

    setActivityFormDatePickers();
  };

  const close = function () {
    $("#contactModal").modal("hide");
    _contact = {};
  };

  const clear = function () {
    _contact = {};
    _wrapper.empty();
  };

  const mainDOM = function () {
    /*eslint-disable */
    return `
        <div class="header">
          <div class="container-fluid" data-item-id="${_contact.key}">
            <div class="header-body mt-n4">
              ${headerDOM()}
              ${tabsDOM()}
            </div>
          </div>
        </div>
      `;
    /*eslint-enable */
  };

  const headerDOM = function () {
    let contactHeaderOptions = '';
    if (_campaign) {
      contactHeaderOptions = `<div id="contact-header-options">
        ${_campaign ? `<div><span><strong>Campaign:</strong> ${_campaign}</span></div>` : ""}
      </div>`
    }

    /* eslint-disable */
    return `
      <div class="row align-items-center">
        <div class="col-auto">
          <div class="avatar avatar-xxl header-avatar-top">
            <img
              src="${_contact.profile.pictureUrl == null
        ? "/assets/img/avatars/profiles/default-avatar.gif"
        : _contact.profile.pictureUrl
      }"
              onerror="this.onerror=null;this.src='/assets/img/avatars/profiles/default-avatar.gif'"
              class="avatar-img rounded-circle border border-4 border-body"
            />
          </div>
        </div>

        <div class="col ml-n3 ml-md-n2">
          <h6 class="header-pretitle">
          ${_contact.profile.headline}
          </h6>
          <div class="header-title">
            <h1 style="display: inline-block;">
              ${_contact.profile.firstName} ${_contact.profile.lastName}
              <a
                href="https://www.linkedin.com/in/${_contact.profile._key.length != 32 ? _contact.profile._key : _contact.profile.linkedin}"
                class="fe fe-link ml-2"
                style="font-size:1.2rem"
                target="_blank"
                data-toggle="tooltip"
                data-placement="top"
                title="Open LinkedIn profile page">
              </a>
            </h1>
            <span class="badge badge-primary ml-2 d-none">
              <span class="fe fe-star"></span> Mark as Lead
            </span>
          </div>
          ${_contact.profile.email ? `<div class="mt-2 text-muted">${_contact.profile.email}</div>` : ""}

          ${contactHeaderOptions}

          <div class="row tags d-none">
              <div class="col-lg-2">
                <span style="color: gray; font-weight: 500; font-size: 13px; margin: 0 15px;">
                  <span class="fe fe-tag"></span>
                    Tags
                </span>
              </div>
              <div class="col-lg-8 present-tags">
                <span class="badge badge-primary mr-1">New</span>
                <span class="badge badge-primary mr-1">New</span>
                <span class="badge badge-primary mr-1">New</span>
                <span class="badge badge-primary mr-1">New</span>
                <span class="badge badge-primary mr-1">New</span>
              </div>
              <div class="col-lg-2">
                <span class="badge badge-secondary ml-auto add-tags-modal">
                  <span class="fe fe-edit"></span>
                  Edit
                </span>
              </div>
          </div>

        </div>
      </div>
    `;
    /* eslint-enable */
  };

  const tabsDOM = function () {
    const activitiesTab = _campaign
      ? `<li class="nav-item">
        <span class="nav-link" id="contact-activities">Activities</span>
      </li>`
      : "";

    const deelTab = _currentLane && _currentLane.name === "hired"
      ? `<li class="nav-item">
        <span class="nav-link" id="contact-deel">Deel</span>
      </li>`
      : "";

    /*eslint-disable */
    return `
      <div class="row align-items-center">
        <div class="col">
          <ul class="nav nav-tabs nav-overflow header-tabs" id="contact-nav-links">

            <li class="nav-item">
              <span class="nav-link active" id="contact-profile">Profile</span>
            </li>
            <li class="nav-item">
              <span class="nav-link" id="contact-companies">Experience</span>
            </li>
            <li class="nav-item">
              <span class="nav-link" id="contact-skills">Skills</span>
            </li>
            <li class="nav-item">
              <span class="nav-link" id="contact-journey">Journey</span>
            </li>
            ${activitiesTab}
            ${deelTab}
          </ul>
        </div>
      </div>
    `;
    /*eslint-enable */
  };

  const loaderDOM = function () {
    return "<div class=\"container-fluid tab-container\" id=\"loader-wait-signals\"></div>";
  };

  const tabsContainerDOM = function () {
    let tabsContent = `
      <div class="container-fluid tab-container" id="contact-profile-container">${profileDOM()}</div>
      <div class="container-fluid tab-container d-none" id="contact-companies-container">${companiesDOM()}</div>
      <div class="container-fluid tab-container d-none" id="contact-skills-container">${skillsDOM()}</div>
      <div class="container-fluid tab-container d-none" id="contact-journey-container">${signalsDOM()}</div>
    `;

    if (_campaign) {
      tabsContent += `
        <div class="container-fluid tab-container d-none" id="contact-activities-container">
          ${activitiesDOM()}
        </div>
      `;
    }

    if (_currentLane && _currentLane.name === "hired") {
      tabsContent += `
        <div class="container-fluid tab-container d-none position-relative h-100" id="contact-deel-container">
          <div id="deel-contracts-container" class="col"></div>
          <div id="deel-form-container" class="col"></div>
        </div>
      `;
    }

    return tabsContent;
  };

  const bindEvents = function () {

    // Change tab
    $("#contactModal .modal-content")
      .off("click", ".nav-link")
      .on("click", ".nav-link", async function () {

        let tabName = $(this).attr("id");
        if ($(this).hasClass("active")) return;

        // Hide all tab containers
        _wrapper.find(".tab-container").addClass("d-none");
        _wrapper.find(".nav-link.active").removeClass("active");

        // Show the necessary one
        $(`#${tabName}-container`).removeClass("d-none");
        $(this).addClass("active");

        // Only call Deel API if user navigates to the Deel tab
        if (tabName === "contact-deel" && !_deelContracts) {
          getDeelData();
        }

      });

    // Add contact to new lane functionality
    $("#contactModal")
      .off("change", "#contact-status-select")
      .on("change", "#contact-status-select", async function () {
        const selectedNewLane = $(this).val();
        if (
          !_contact.profile?._key ||
          !_currentLane ||
          !selectedNewLane ||
          !_addToNewLane
        ) return;

        stent.loader.show("#contact-status-select-wrapper");

        // Add to new lane (back-end)
        _currentLane = await _addToNewLane(_contact.profile._key, _currentLane.name, selectedNewLane);

        stent.loader.hide();
      });

    // Toggle hidden widgets
    $("#contactModal")
      .off("click", "#additional-profile-info-button")
      .on("click", "#additional-profile-info-button", function () {
        const button = this;
        const status = $(button).data("status");
        if (status === "showing") {
          $(button).data("status", "hidden");
          $(button).children(".action").text("Show");
          $(button).children("span.fe-eye").removeClass("fe-eye").addClass("fe-eye-off");

          $("#profile-hidden-widgets-row").addClass("d-none");
        } else if (status === "hidden") {
          $(button).data("status", "showing");
          $(button).children(".action").text("Hide");
          $(button).children("span.fe-eye-off").removeClass("fe-eye-off").addClass("fe-eye");

          $("#profile-hidden-widgets-row").removeClass("d-none");

          const scrollTop = $("#contactModal .modal-content").scrollTop();
          $("#contactModal .modal-content").scrollTop(scrollTop + 100);
        }
      });

    // Toggle google maps iframe
    $("#contactModal")
      .off("click", "#toggle-profile-map")
      .on("click", "#toggle-profile-map", function () {
        $(this).toggleClass("fe-eye");
        $(this).toggleClass("fe-eye-off");
        $("#profile-map-outer-container").toggleClass("d-none");
      });

    // Validate notes input
    $("#contactModal")
      .off("input", "#add-note-form input")
      .on("input", "#add-note-form input", function () {
        const inputValue = $(this).val().trim();
        const form = $("#add-note-form");

        if (inputValue === "") {
          // Invalid form
          $(form).find(`button[type="submit"]`).prop("disabled", true);
          $(this).addClass("is-invalid");
        } else {
          // Valid form
          $(form).find(`button[type="submit"]`).prop("disabled", false);
          $(this).removeClass("is-invalid");
        }
      });

    // Add new profile note
    $("#contactModal")
      .off("submit", "#add-note-form")
      .on("submit", "#add-note-form", async function (event) {
        event.preventDefault();
        const newNoteInput = $(this).find("input");
        const newNoteText = newNoteInput.val().trim();
        if (newNoteText === "") return;

        const addNoteFormButton = $(this).find(`button[type="submit"]`);
        stent.loader.show(addNoteFormButton, 2000);

        const response = await addNote(newNoteText);

        if (response.ok && response.message) {
          const createdNote = response.message;
          $("#notes-card-container")
            .find(".card-body")
            .prepend(`<span>${createdNote.content.trim()}</span>`);
          $(newNoteInput).val("");

          $("#notes-card-container").scrollTop(0);
        } else {
          stent.toast.danger("There was an error adding new note.");
        }

        stent.loader.hide();
      });

    // Show or hide the new activity form
    $("#contactModal")
      .off("click", "#add-activity-button")
      .on("click", "#add-activity-button", async function () {
        const activityForm = $("#add-activity-form");
        activityForm.toggleClass("d-none");
        $(this).toggleClass("btn-outline-secondary");
        $(this).toggleClass("btn-secondary");
      });

    // Enter new activity content
    $("#contactModal")
      .off("input", `#add-activity-form input[name="activity-content"]`)
      .on("input", `#add-activity-form input[name="activity-content"]`, async function () {
        const enteredValue = $(this).val();
        if (enteredValue.trim() === "") {
          $(this).addClass("is-invalid");
        } else {
          $(this).removeClass("is-invalid");
        }
        _activityForm.content = enteredValue;

        validateActivityForm();
      });

    // Select activity type in form
    $("#contactModal")
      .off("click", "#add-activity-form .new-note-type")
      .on("click", "#add-activity-form .new-note-type", async function () {
        $(this).addClass("selected");
        $(this).siblings().removeClass("selected");
        _activityForm.type = $(this).data("type");

        validateActivityForm();
      });

    // Submit new activity form
    $("#contactModal")
      .off("submit", "#add-activity-form")
      .on("submit", "#add-activity-form", async function (event) {
        event.preventDefault();
        if (!isActivityFormValid()) return;

        const startDate = _activityForm.startDate.valueOf();
        const endDate = _activityForm.endDate?.valueOf();

        const body = {
          tenantKey: stent.tenant.key,
          userKey: stent.user?.id,
          campaignName: _campaign,
          content: _activityForm.content,
          type: _activityForm.type,
          startDate,
          endDate,
        }

        stent.loader.show($(this), 2000);

        const response = await stent.ajax.postRestAsync(`/profiles/${_contact.profile._key}/activities`, body);

        if (response.ok && response.message) {
          // Replace displayed latest activity
          const { content: newContent, startDate: newStartDate, endDate: newEndDate, owner: newOwner, type: newType } = response.message;
          const newStartDateDisplay = new Date(newStartDate)?.toLocaleDateString();
          let latestActivity = _contact.activities && _contact.activities.length > 0
            ? _contact.activities[0]
            : null;
          // Only replace the latest activity text if start date is higher than the last one
          if (!latestActivity || moment(latestActivity.startDate) <= moment(newStartDate)) {
            $("#latest-activity-text").text(`${newContent}${newStartDateDisplay ? ` - ${newStartDateDisplay}` : ""}`);
          }
          _contact.activities.unshift({
            content: newContent,
            type: newType,
            owner: newOwner,
            startDate: newStartDate,
            endDate: newEndDate,
          });
          _contact.activities.sort((a, b) => a.startDate < b.startDate ? 1 : -1);

          // Reset form
          $(`#add-activity-form input[name="activity-content"]`).val("");

          $("#add-activity-form .new-note-type").removeClass("selected");

          const now = moment();
          $("#activity-start-date").data("daterangepicker").setStartDate(now);
          $("#activity-start-date").data("daterangepicker").setEndDate(now);
          $("#activity-end-date").data("daterangepicker").setStartDate(now);
          $("#activity-end-date").data("daterangepicker").setEndDate(now);
          $("#activity-start-date-input").text("Start Date");
          $("#activity-end-date-input").text("End Date");

          $(`#add-activity-form button[type="submit"]`).prop("disabled", true);

          _activityForm = {};

          // Update Activities Tab
          $("#contact-activities-container").html(activitiesDOM());
        } else {
          stent.toast.danger("There was an error adding new activity.");
        }

        stent.loader.hide();
        stent.toast.success("Activity added successfully.");
      });

    // Create new contract clicked
    $("#contactModal")
      .off("click", "#deel-create-contract-button")
      .on("click", "#deel-create-contract-button", function () {
        $("#create-contract-form").removeClass("d-none");
        $(this).addClass("d-none");
      });

    // Cancel contract form
    $("#contactModal")
      .off("click", "#cancel-deel-contract-button")
      .on("click", "#cancel-deel-contract-button", function (event) {
        $("#create-contract-form").addClass("d-none");
        $("#deel-create-contract-button").removeClass("d-none");
      });

    // Deel Contract nationality selected
    $("#contactModal")
      .off("change", "#employee-country")
      .on("change", "#employee-country", function () {
        const selectedCountryCode = $(this).val();
        if (!selectedCountryCode) return;

        // Update the available states for new selected country
        setDeelFormStates("employee-state", selectedCountryCode);
      });

    // Deel Contract employment country selected
    $("#contactModal")
      .off("change", "#employment-country")
      .on("change", "#employment-country", function () {
        const selectedCountryCode = $(this).val();
        if (!selectedCountryCode) return;

        // Update the available states for new selected country
        setDeelFormStates("employment-state", selectedCountryCode);

        fetchDeelCountryGuide(selectedCountryCode);
      });

    // Deel Contract health insurance provider selected
    $("#contactModal")
      .off("change", "#health-insurance-provider")
      .on("change", "#health-insurance-provider", function () {
        const selectedProvider = $(this).val();
        if (!selectedProvider) return;

        // Update the available plans for selected provider
        updateFormHealthInsurancePlans(selectedProvider);
      });

    // Deel Contract employment type selected
    $("#contactModal")
      .off("change", "#employment-type")
      .on("change", "#employment-type", function () {
        // Update the probation period validation for selected type
        updateProbationPeriod();
      });

    // Validate form input on change
    $("#contactModal")
      .off("change blur", "#create-contract-form :input")
      .on("change blur", "#create-contract-form :input", function () {
        if (!_contractValidator) return;

        // Validate the form input
        _contractValidator.element($(this));
      });

    // Submit Deel contract form
    $("#contactModal")
      .off("submit", "#create-contract-form")
      .on("submit", "#create-contract-form", function (event) {
        event.preventDefault();

        const isValid = $("#create-contract-form").valid();
        if (!isValid) return;

        // Get all form fields and transform to object
        const formFieldsArray = $(this).serializeArray();
        const contractBody = formFieldsArray.reduce((map, obj) => {
          map[obj.name] = obj.value === "" ? null : obj.value;
          return map
        }, {});

        // Manually set date, number and boolean properties
        if(contractBody["probationPeriod"] == null) contractBody["probationPeriod"] = 0;
        contractBody["employmentWorkVisaRequired"] = $("#employment-visa").is(":checked");
        const startDate = $("#employment-start-date-input").val();
        const startDateInt = parseInt(startDate, 10);
        const startDateFormatted = moment(startDateInt).format("YYYY-MM-DD");
        contractBody["employmentStartDate"] = startDateFormatted;

        postDeelContract(contractBody);
      });
  };

  const deelContractFormValidation = function () {
    // Initialize form validation on the deel contract form.
    _contractValidator = $("#create-contract-form").validate({
      // Specify validation rules
      rules: {
        employeeFirstName: "required",
        employeeLastName: "required",
        employeeEmail: {
          required: true,
          email: true
        },
        employeeCountry: "required",
        employeeStreet: "required",
        employeeCity: "required",
        employeeState: "required",
        employeeZip: "required",
        employmentCountry: "required",
        employmentType: "required",
        probationPeriod: {
          number: true,
        },
        employmentStartDate: "required",
        jobTitle: "required",
        scopeOfWork: "required",
        seniorityLevel: "required",
        compensationCurrency: "required",
        compensationSalary: {
          required: true,
          number: true,
        },
      },
      // Specify validation error messages
      messages: {
        employeeFirstName: "First name is required",
        employeeLastName: "Last name is required",
        employeeEmail: {
          required: "Email address is required",
          email: "Email address is invalid"
        },
        employeeCountry: "Nationality is required",
        employeeStreet: "Address is required",
        employeeCity: "City is required",
        employeeState: "State is required",
        employeeZip: "Zip code is required",
        employmentCountry: "Employment country is required",
        employmentType: "Employment type is required",
        probationPeriod: {
          required: "Probation period is required",
          number: "Probation period must be a number of days",
        },
        employmentStartDate: "Start date is required",
        jobTitle: "Job title is required",
        scopeOfWork: "Job description is required",
        seniorityLevel: "Seniority level is required",
        compensationCurrency: "Currency is required",
        compensationSalary: {
          required: "Salary is required",
          number: "Salary must be a valid number",
        },
      },
    });
  };

  const init = function () {
    bindEvents();
  };

  init();

  return {
    open,
    close,
    clear,
    get: function () {
      return _contact;
    },
  };
})();

stent.tooltip = (function() {

  var init = function () {

    $("body").tooltip({
      selector: "[data-toggle=\"tooltip\"]",
      delay: parseInt($(this).attr("data-delay"), 10),
      sanitize: false,
      boundary: "window",
      trigger: "hover"
    });

    $("body").on("click.tooltip", "[data-toggle='tooltip']", function() {
      $(this).tooltip("hide");
    });

  };

  init();

})();
stent.select2 = (function() {

  return {
    memberLayout: {
      escapeMarkup: function(markup) {
        return markup;
      },
      templateResult: function(data) {

        let pictureUrl = data.pictureUrl ? data.pictureUrl : "/assets/img/avatars/profiles/default-avatar.gif";

        return `
          <div>
            <div class="avatar avatar-xs">
              <img src="${pictureUrl}" onerror="this.onerror=null;this.src='/assets/img/avatars/profiles/default-avatar.gif';" class="avatar-img rounded-circle" />
            </div>
            <div style="display: inline-block; vertical-align: -2px; margin-left: 5px;">
              ${data.text}
            <div>
          </div>`;
      },
      templateSelection: function(data) {

        let pictureUrl = data.pictureUrl ? data.pictureUrl : "/assets/img/avatars/profiles/default-avatar.gif";

        return `
        <div style="margin-top: -2px; margin-left: -5px;">
          <div class="avatar avatar-xs" style="width: 1rem; height: 1rem;">
            <img src="${pictureUrl}" onerror="this.onerror=null;this.src='/assets/img/avatars/profiles/default-avatar.gif';" class="avatar-img rounded-circle" />
          </div>
          <div style="display: inline-block; vertical-align: -2px; margin-left: 5px;">
            ${data.text}
          <div>
        </div>`;
      }
    }

  };

})();

"use strict";

stent.cronEditor = (function() {

  // editors
  let editors = {};
  let defaultDuration = "PT3H";


  const get = function (editorId) {
    updateEditors();
    return editors[editorId];
  };


  const durationPickerSetup = function (value) {
    return {
      seconds: false,
      years: false,
      defaultValue: value ? value : defaultDuration,
      onSelect: function(element, seconds, duration, text) {
        let editorKey = element.closest(".stent-cron-editor").attr("id");
        let cronIndex = element.closest("tr").index() - 1;
        editors[editorKey].crons[cronIndex].duration = duration;
        $(element).val(text);
      }
    };
  };


  const getAll = function () {
    updateEditors();
    return editors;
  };


  const updateEditors = function () {

    for (var key in editors) {

      if ($("#" + key).length > 0) {
        // Update timezone
        editors[key].timezone = $("#" + key + " .stent-cron-timezone").val();

        // Update CRONS data
        let crons = editors[key].crons;
        for (var i = 0; i < crons.length; i++) {
          crons[i].cron = crons[i].jCron.cron("value");
        }
      }

    }

  };


  const timezonesDOM = function (id) {
    /* eslint-disable */
    return `
    <select class="form-control form-control-input stent-cron-timezone" id="${id}">
      <option value="">Select a timezone</option>
      <option data-offset="-12:00" value="Etc/GMT+12">(GMT -12:00) Etc/GMT+12</option>
      <option data-offset="-11:00" value="Etc/GMT+11">(GMT -11:00) Etc/GMT+11</option>
      <option data-offset="-11:00" value="Pacific/Midway">(GMT -11:00) Pacific/Midway</option>
      <option data-offset="-11:00" value="Pacific/Niue">(GMT -11:00) Pacific/Niue</option>
      <option data-offset="-11:00" value="Pacific/Pago_Pago">(GMT -11:00) Pacific/Pago_Pago</option>
      <option data-offset="-11:00" value="Pacific/Samoa">(GMT -11:00) Pacific/Samoa</option>
      <option data-offset="-11:00" value="US/Samoa">(GMT -11:00) US/Samoa</option>
      <option data-offset="-10:00" value="Etc/GMT+10">(GMT -10:00) Etc/GMT+10</option>
      <option data-offset="-10:00" value="HST">(GMT -10:00) HST</option>
      <option data-offset="-10:00" value="Pacific/Honolulu">(GMT -10:00) Pacific/Honolulu</option>
      <option data-offset="-10:00" value="Pacific/Johnston">(GMT -10:00) Pacific/Johnston</option>
      <option data-offset="-10:00" value="Pacific/Rarotonga">(GMT -10:00) Pacific/Rarotonga</option>
      <option data-offset="-10:00" value="Pacific/Tahiti">(GMT -10:00) Pacific/Tahiti</option>
      <option data-offset="-10:00" value="US/Hawaii">(GMT -10:00) US/Hawaii</option>
      <option data-offset="-09:30" value="Pacific/Marquesas">(GMT -09:30) Pacific/Marquesas</option>
      <option data-offset="-09:00" value="America/Adak">(GMT -09:00) America/Adak</option>
      <option data-offset="-09:00" value="America/Atka">(GMT -09:00) America/Atka</option>
      <option data-offset="-09:00" value="Etc/GMT+9">(GMT -09:00) Etc/GMT+9</option>
      <option data-offset="-09:00" value="Pacific/Gambier">(GMT -09:00) Pacific/Gambier</option>
      <option data-offset="-09:00" value="US/Aleutian">(GMT -09:00) US/Aleutian</option>
      <option data-offset="-08:00" value="America/Anchorage">(GMT -08:00) America/Anchorage</option>
      <option data-offset="-08:00" value="America/Juneau">(GMT -08:00) America/Juneau</option>
      <option data-offset="-08:00" value="America/Metlakatla">(GMT -08:00) America/Metlakatla</option>
      <option data-offset="-08:00" value="America/Nome">(GMT -08:00) America/Nome</option>
      <option data-offset="-08:00" value="America/Sitka">(GMT -08:00) America/Sitka</option>
      <option data-offset="-08:00" value="America/Yakutat">(GMT -08:00) America/Yakutat</option>
      <option data-offset="-08:00" value="Etc/GMT+8">(GMT -08:00) Etc/GMT+8</option>
      <option data-offset="-08:00" value="Pacific/Pitcairn">(GMT -08:00) Pacific/Pitcairn</option>
      <option data-offset="-08:00" value="US/Alaska">(GMT -08:00) US/Alaska</option>
      <option data-offset="-07:00" value="America/Creston">(GMT -07:00) America/Creston</option>
      <option data-offset="-07:00" value="America/Dawson">(GMT -07:00) America/Dawson</option>
      <option data-offset="-07:00" value="America/Dawson_Creek">(GMT -07:00) America/Dawson_Creek</option>
      <option data-offset="-07:00" value="America/Ensenada">(GMT -07:00) America/Ensenada</option>
      <option data-offset="-07:00" value="America/Hermosillo">(GMT -07:00) America/Hermosillo</option>
      <option data-offset="-07:00" value="America/Los_Angeles">(GMT -07:00) America/Los_Angeles</option>
      <option data-offset="-07:00" value="America/Phoenix">(GMT -07:00) America/Phoenix</option>
      <option data-offset="-07:00" value="America/Santa_Isabel">(GMT -07:00) America/Santa_Isabel</option>
      <option data-offset="-07:00" value="America/Tijuana">(GMT -07:00) America/Tijuana</option>
      <option data-offset="-07:00" value="America/Vancouver">(GMT -07:00) America/Vancouver</option>
      <option data-offset="-07:00" value="America/Whitehorse">(GMT -07:00) America/Whitehorse</option>
      <option data-offset="-07:00" value="Canada/Pacific">(GMT -07:00) Canada/Pacific</option>
      <option data-offset="-07:00" value="Canada/Yukon">(GMT -07:00) Canada/Yukon</option>
      <option data-offset="-07:00" value="Etc/GMT+7">(GMT -07:00) Etc/GMT+7</option>
      <option data-offset="-07:00" value="MST">(GMT -07:00) MST</option>
      <option data-offset="-07:00" value="Mexico/BajaNorte">(GMT -07:00) Mexico/BajaNorte</option>
      <option data-offset="-07:00" value="PST8PDT">(GMT -07:00) PST8PDT</option>
      <option data-offset="-07:00" value="US/Arizona">(GMT -07:00) US/Arizona</option>
      <option data-offset="-07:00" value="US/Pacific">(GMT -07:00) US/Pacific</option>
      <option data-offset="-07:00" value="US/Pacific-New">(GMT -07:00) US/Pacific-New</option>
      <option data-offset="-06:00" value="America/Belize">(GMT -06:00) America/Belize</option>
      <option data-offset="-06:00" value="America/Boise">(GMT -06:00) America/Boise</option>
      <option data-offset="-06:00" value="America/Cambridge_Bay">(GMT -06:00) America/Cambridge_Bay</option>
      <option data-offset="-06:00" value="America/Chihuahua">(GMT -06:00) America/Chihuahua</option>
      <option data-offset="-06:00" value="America/Costa_Rica">(GMT -06:00) America/Costa_Rica</option>
      <option data-offset="-06:00" value="America/Denver">(GMT -06:00) America/Denver</option>
      <option data-offset="-06:00" value="America/Edmonton">(GMT -06:00) America/Edmonton</option>
      <option data-offset="-06:00" value="America/El_Salvador">(GMT -06:00) America/El_Salvador</option>
      <option data-offset="-06:00" value="America/Guatemala">(GMT -06:00) America/Guatemala</option>
      <option data-offset="-06:00" value="America/Inuvik">(GMT -06:00) America/Inuvik</option>
      <option data-offset="-06:00" value="America/Managua">(GMT -06:00) America/Managua</option>
      <option data-offset="-06:00" value="America/Mazatlan">(GMT -06:00) America/Mazatlan</option>
      <option data-offset="-06:00" value="America/Ojinaga">(GMT -06:00) America/Ojinaga</option>
      <option data-offset="-06:00" value="America/Regina">(GMT -06:00) America/Regina</option>
      <option data-offset="-06:00" value="America/Shiprock">(GMT -06:00) America/Shiprock</option>
      <option data-offset="-06:00" value="America/Swift_Current">(GMT -06:00) America/Swift_Current</option>
      <option data-offset="-06:00" value="America/Tegucigalpa">(GMT -06:00) America/Tegucigalpa</option>
      <option data-offset="-06:00" value="America/Yellowknife">(GMT -06:00) America/Yellowknife</option>
      <option data-offset="-06:00" value="Canada/East-Saskatchewan">(GMT -06:00) Canada/East-Saskatchewan</option>
      <option data-offset="-06:00" value="Canada/Mountain">(GMT -06:00) Canada/Mountain</option>
      <option data-offset="-06:00" value="Canada/Saskatchewan">(GMT -06:00) Canada/Saskatchewan</option>
      <option data-offset="-06:00" value="Chile/EasterIsland">(GMT -06:00) Chile/EasterIsland</option>
      <option data-offset="-06:00" value="Etc/GMT+6">(GMT -06:00) Etc/GMT+6</option>
      <option data-offset="-06:00" value="MST7MDT">(GMT -06:00) MST7MDT</option>
      <option data-offset="-06:00" value="Mexico/BajaSur">(GMT -06:00) Mexico/BajaSur</option>
      <option data-offset="-06:00" value="Navajo">(GMT -06:00) Navajo</option>
      <option data-offset="-06:00" value="Pacific/Easter">(GMT -06:00) Pacific/Easter</option>
      <option data-offset="-06:00" value="Pacific/Galapagos">(GMT -06:00) Pacific/Galapagos</option>
      <option data-offset="-06:00" value="US/Mountain">(GMT -06:00) US/Mountain</option>
      <option data-offset="-05:00" value="America/Atikokan">(GMT -05:00) America/Atikokan</option>
      <option data-offset="-05:00" value="America/Bahia_Banderas">(GMT -05:00) America/Bahia_Banderas</option>
      <option data-offset="-05:00" value="America/Bogota">(GMT -05:00) America/Bogota</option>
      <option data-offset="-05:00" value="America/Cancun">(GMT -05:00) America/Cancun</option>
      <option data-offset="-05:00" value="America/Cayman">(GMT -05:00) America/Cayman</option>
      <option data-offset="-05:00" value="America/Chicago">(GMT -05:00) America/Chicago</option>
      <option data-offset="-05:00" value="America/Coral_Harbour">(GMT -05:00) America/Coral_Harbour</option>
      <option data-offset="-05:00" value="America/Eirunepe">(GMT -05:00) America/Eirunepe</option>
      <option data-offset="-05:00" value="America/Guayaquil">(GMT -05:00) America/Guayaquil</option>
      <option data-offset="-05:00" value="America/Indiana/Knox">(GMT -05:00) America/Indiana/Knox</option>
      <option data-offset="-05:00" value="America/Indiana/Tell_City">(GMT -05:00) America/Indiana/Tell_City</option>
      <option data-offset="-05:00" value="America/Jamaica">(GMT -05:00) America/Jamaica</option>
      <option data-offset="-05:00" value="America/Knox_IN">(GMT -05:00) America/Knox_IN</option>
      <option data-offset="-05:00" value="America/Lima">(GMT -05:00) America/Lima</option>
      <option data-offset="-05:00" value="America/Matamoros">(GMT -05:00) America/Matamoros</option>
      <option data-offset="-05:00" value="America/Menominee">(GMT -05:00) America/Menominee</option>
      <option data-offset="-05:00" value="America/Merida">(GMT -05:00) America/Merida</option>
      <option data-offset="-05:00" value="America/Mexico_City">(GMT -05:00) America/Mexico_City</option>
      <option data-offset="-05:00" value="America/Monterrey">(GMT -05:00) America/Monterrey</option>
      <option data-offset="-05:00" value="America/North_Dakota/Beulah">(GMT -05:00) America/North_Dakota/Beulah</option>
      <option data-offset="-05:00" value="America/North_Dakota/Center">(GMT -05:00) America/North_Dakota/Center</option>
      <option data-offset="-05:00" value="America/North_Dakota/New_Salem">(GMT -05:00) America/North_Dakota/New_Salem</option>
      <option data-offset="-05:00" value="America/Panama">(GMT -05:00) America/Panama</option>
      <option data-offset="-05:00" value="America/Porto_Acre">(GMT -05:00) America/Porto_Acre</option>
      <option data-offset="-05:00" value="America/Rainy_River">(GMT -05:00) America/Rainy_River</option>
      <option data-offset="-05:00" value="America/Rankin_Inlet">(GMT -05:00) America/Rankin_Inlet</option>
      <option data-offset="-05:00" value="America/Resolute">(GMT -05:00) America/Resolute</option>
      <option data-offset="-05:00" value="America/Rio_Branco">(GMT -05:00) America/Rio_Branco</option>
      <option data-offset="-05:00" value="America/Winnipeg">(GMT -05:00) America/Winnipeg</option>
      <option data-offset="-05:00" value="Brazil/Acre">(GMT -05:00) Brazil/Acre</option>
      <option data-offset="-05:00" value="CST6CDT">(GMT -05:00) CST6CDT</option>
      <option data-offset="-05:00" value="Canada/Central">(GMT -05:00) Canada/Central</option>
      <option data-offset="-05:00" value="EST">(GMT -05:00) EST</option>
      <option data-offset="-05:00" value="Etc/GMT+5">(GMT -05:00) Etc/GMT+5</option>
      <option data-offset="-05:00" value="Jamaica">(GMT -05:00) Jamaica</option>
      <option data-offset="-05:00" value="Mexico/General">(GMT -05:00) Mexico/General</option>
      <option data-offset="-05:00" value="US/Central">(GMT -05:00) US/Central</option>
      <option data-offset="-05:00" value="US/Indiana-Starke">(GMT -05:00) US/Indiana-Starke</option>
      <option data-offset="-04:30" value="America/Caracas">(GMT -04:30) America/Caracas</option>
      <option data-offset="-04:00" value="America/Anguilla">(GMT -04:00) America/Anguilla</option>
      <option data-offset="-04:00" value="America/Antigua">(GMT -04:00) America/Antigua</option>
      <option data-offset="-04:00" value="America/Aruba">(GMT -04:00) America/Aruba</option>
      <option data-offset="-04:00" value="America/Asuncion">(GMT -04:00) America/Asuncion</option>
      <option data-offset="-04:00" value="America/Barbados">(GMT -04:00) America/Barbados</option>
      <option data-offset="-04:00" value="America/Blanc-Sablon">(GMT -04:00) America/Blanc-Sablon</option>
      <option data-offset="-04:00" value="America/Boa_Vista">(GMT -04:00) America/Boa_Vista</option>
      <option data-offset="-04:00" value="America/Campo_Grande">(GMT -04:00) America/Campo_Grande</option>
      <option data-offset="-04:00" value="America/Cuiaba">(GMT -04:00) America/Cuiaba</option>
      <option data-offset="-04:00" value="America/Curacao">(GMT -04:00) America/Curacao</option>
      <option data-offset="-04:00" value="America/Detroit">(GMT -04:00) America/Detroit</option>
      <option data-offset="-04:00" value="America/Dominica">(GMT -04:00) America/Dominica</option>
      <option data-offset="-04:00" value="America/Fort_Wayne">(GMT -04:00) America/Fort_Wayne</option>
      <option data-offset="-04:00" value="America/Grand_Turk">(GMT -04:00) America/Grand_Turk</option>
      <option data-offset="-04:00" value="America/Grenada">(GMT -04:00) America/Grenada</option>
      <option data-offset="-04:00" value="America/Guadeloupe">(GMT -04:00) America/Guadeloupe</option>
      <option data-offset="-04:00" value="America/Guyana">(GMT -04:00) America/Guyana</option>
      <option data-offset="-04:00" value="America/Havana">(GMT -04:00) America/Havana</option>
      <option data-offset="-04:00" value="America/Indiana/Indianapolis">(GMT -04:00) America/Indiana/Indianapolis</option>
      <option data-offset="-04:00" value="America/Indiana/Marengo">(GMT -04:00) America/Indiana/Marengo</option>
      <option data-offset="-04:00" value="America/Indiana/Petersburg">(GMT -04:00) America/Indiana/Petersburg</option>
      <option data-offset="-04:00" value="America/Indiana/Vevay">(GMT -04:00) America/Indiana/Vevay</option>
      <option data-offset="-04:00" value="America/Indiana/Vincennes">(GMT -04:00) America/Indiana/Vincennes</option>
      <option data-offset="-04:00" value="America/Indiana/Winamac">(GMT -04:00) America/Indiana/Winamac</option>
      <option data-offset="-04:00" value="America/Indianapolis">(GMT -04:00) America/Indianapolis</option>
      <option data-offset="-04:00" value="America/Iqaluit">(GMT -04:00) America/Iqaluit</option>
      <option data-offset="-04:00" value="America/Kentucky/Louisville">(GMT -04:00) America/Kentucky/Louisville</option>
      <option data-offset="-04:00" value="America/Kentucky/Monticello">(GMT -04:00) America/Kentucky/Monticello</option>
      <option data-offset="-04:00" value="America/Kralendijk">(GMT -04:00) America/Kralendijk</option>
      <option data-offset="-04:00" value="America/La_Paz">(GMT -04:00) America/La_Paz</option>
      <option data-offset="-04:00" value="America/Louisville">(GMT -04:00) America/Louisville</option>
      <option data-offset="-04:00" value="America/Lower_Princes">(GMT -04:00) America/Lower_Princes</option>
      <option data-offset="-04:00" value="America/Manaus">(GMT -04:00) America/Manaus</option>
      <option data-offset="-04:00" value="America/Marigot">(GMT -04:00) America/Marigot</option>
      <option data-offset="-04:00" value="America/Martinique">(GMT -04:00) America/Martinique</option>
      <option data-offset="-04:00" value="America/Montreal">(GMT -04:00) America/Montreal</option>
      <option data-offset="-04:00" value="America/Montserrat">(GMT -04:00) America/Montserrat</option>
      <option data-offset="-04:00" value="America/Nassau">(GMT -04:00) America/Nassau</option>
      <option data-offset="-04:00" value="America/New_York">(GMT -04:00) America/New_York</option>
      <option data-offset="-04:00" value="America/Nipigon">(GMT -04:00) America/Nipigon</option>
      <option data-offset="-04:00" value="America/Pangnirtung">(GMT -04:00) America/Pangnirtung</option>
      <option data-offset="-04:00" value="America/Port-au-Prince">(GMT -04:00) America/Port-au-Prince</option>
      <option data-offset="-04:00" value="America/Port_of_Spain">(GMT -04:00) America/Port_of_Spain</option>
      <option data-offset="-04:00" value="America/Porto_Velho">(GMT -04:00) America/Porto_Velho</option>
      <option data-offset="-04:00" value="America/Puerto_Rico">(GMT -04:00) America/Puerto_Rico</option>
      <option data-offset="-04:00" value="America/Santiago">(GMT -04:00) America/Santiago</option>
      <option data-offset="-04:00" value="America/Santo_Domingo">(GMT -04:00) America/Santo_Domingo</option>
      <option data-offset="-04:00" value="America/St_Barthelemy">(GMT -04:00) America/St_Barthelemy</option>
      <option data-offset="-04:00" value="America/St_Kitts">(GMT -04:00) America/St_Kitts</option>
      <option data-offset="-04:00" value="America/St_Lucia">(GMT -04:00) America/St_Lucia</option>
      <option data-offset="-04:00" value="America/St_Thomas">(GMT -04:00) America/St_Thomas</option>
      <option data-offset="-04:00" value="America/St_Vincent">(GMT -04:00) America/St_Vincent</option>
      <option data-offset="-04:00" value="America/Thunder_Bay">(GMT -04:00) America/Thunder_Bay</option>
      <option data-offset="-04:00" value="America/Toronto">(GMT -04:00) America/Toronto</option>
      <option data-offset="-04:00" value="America/Tortola">(GMT -04:00) America/Tortola</option>
      <option data-offset="-04:00" value="America/Virgin">(GMT -04:00) America/Virgin</option>
      <option data-offset="-04:00" value="Antarctica/Palmer">(GMT -04:00) Antarctica/Palmer</option>
      <option data-offset="-04:00" value="Brazil/West">(GMT -04:00) Brazil/West</option>
      <option data-offset="-04:00" value="Canada/Eastern">(GMT -04:00) Canada/Eastern</option>
      <option data-offset="-04:00" value="Chile/Continental">(GMT -04:00) Chile/Continental</option>
      <option data-offset="-04:00" value="Cuba">(GMT -04:00) Cuba</option>
      <option data-offset="-04:00" value="EST5EDT">(GMT -04:00) EST5EDT</option>
      <option data-offset="-04:00" value="Etc/GMT+4">(GMT -04:00) Etc/GMT+4</option>
      <option data-offset="-04:00" value="US/East-Indiana">(GMT -04:00) US/East-Indiana</option>
      <option data-offset="-04:00" value="US/Eastern">(GMT -04:00) US/Eastern</option>
      <option data-offset="-04:00" value="US/Michigan">(GMT -04:00) US/Michigan</option>
      <option data-offset="-03:00" value="America/Araguaina">(GMT -03:00) America/Araguaina</option>
      <option data-offset="-03:00" value="America/Argentina/Buenos_Aires">(GMT -03:00) America/Argentina/Buenos_Aires</option>
      <option data-offset="-03:00" value="America/Argentina/Catamarca">(GMT -03:00) America/Argentina/Catamarca</option>
      <option data-offset="-03:00" value="America/Argentina/ComodRivadavia">(GMT -03:00) America/Argentina/ComodRivadavia</option>
      <option data-offset="-03:00" value="America/Argentina/Cordoba">(GMT -03:00) America/Argentina/Cordoba</option>
      <option data-offset="-03:00" value="America/Argentina/Jujuy">(GMT -03:00) America/Argentina/Jujuy</option>
      <option data-offset="-03:00" value="America/Argentina/La_Rioja">(GMT -03:00) America/Argentina/La_Rioja</option>
      <option data-offset="-03:00" value="America/Argentina/Mendoza">(GMT -03:00) America/Argentina/Mendoza</option>
      <option data-offset="-03:00" value="America/Argentina/Rio_Gallegos">(GMT -03:00) America/Argentina/Rio_Gallegos</option>
      <option data-offset="-03:00" value="America/Argentina/Salta">(GMT -03:00) America/Argentina/Salta</option>
      <option data-offset="-03:00" value="America/Argentina/San_Juan">(GMT -03:00) America/Argentina/San_Juan</option>
      <option data-offset="-03:00" value="America/Argentina/San_Luis">(GMT -03:00) America/Argentina/San_Luis</option>
      <option data-offset="-03:00" value="America/Argentina/Tucuman">(GMT -03:00) America/Argentina/Tucuman</option>
      <option data-offset="-03:00" value="America/Argentina/Ushuaia">(GMT -03:00) America/Argentina/Ushuaia</option>
      <option data-offset="-03:00" value="America/Bahia">(GMT -03:00) America/Bahia</option>
      <option data-offset="-03:00" value="America/Belem">(GMT -03:00) America/Belem</option>
      <option data-offset="-03:00" value="America/Buenos_Aires">(GMT -03:00) America/Buenos_Aires</option>
      <option data-offset="-03:00" value="America/Catamarca">(GMT -03:00) America/Catamarca</option>
      <option data-offset="-03:00" value="America/Cayenne">(GMT -03:00) America/Cayenne</option>
      <option data-offset="-03:00" value="America/Cordoba">(GMT -03:00) America/Cordoba</option>
      <option data-offset="-03:00" value="America/Fortaleza">(GMT -03:00) America/Fortaleza</option>
      <option data-offset="-03:00" value="America/Glace_Bay">(GMT -03:00) America/Glace_Bay</option>
      <option data-offset="-03:00" value="America/Goose_Bay">(GMT -03:00) America/Goose_Bay</option>
      <option data-offset="-03:00" value="America/Halifax">(GMT -03:00) America/Halifax</option>
      <option data-offset="-03:00" value="America/Jujuy">(GMT -03:00) America/Jujuy</option>
      <option data-offset="-03:00" value="America/Maceio">(GMT -03:00) America/Maceio</option>
      <option data-offset="-03:00" value="America/Mendoza">(GMT -03:00) America/Mendoza</option>
      <option data-offset="-03:00" value="America/Moncton">(GMT -03:00) America/Moncton</option>
      <option data-offset="-03:00" value="America/Montevideo">(GMT -03:00) America/Montevideo</option>
      <option data-offset="-03:00" value="America/Paramaribo">(GMT -03:00) America/Paramaribo</option>
      <option data-offset="-03:00" value="America/Recife">(GMT -03:00) America/Recife</option>
      <option data-offset="-03:00" value="America/Rosario">(GMT -03:00) America/Rosario</option>
      <option data-offset="-03:00" value="America/Santarem">(GMT -03:00) America/Santarem</option>
      <option data-offset="-03:00" value="America/Sao_Paulo">(GMT -03:00) America/Sao_Paulo</option>
      <option data-offset="-03:00" value="America/Thule">(GMT -03:00) America/Thule</option>
      <option data-offset="-03:00" value="Antarctica/Rothera">(GMT -03:00) Antarctica/Rothera</option>
      <option data-offset="-03:00" value="Atlantic/Bermuda">(GMT -03:00) Atlantic/Bermuda</option>
      <option data-offset="-03:00" value="Atlantic/Stanley">(GMT -03:00) Atlantic/Stanley</option>
      <option data-offset="-03:00" value="Brazil/East">(GMT -03:00) Brazil/East</option>
      <option data-offset="-03:00" value="Canada/Atlantic">(GMT -03:00) Canada/Atlantic</option>
      <option data-offset="-03:00" value="Etc/GMT+3">(GMT -03:00) Etc/GMT+3</option>
      <option data-offset="-02:30" value="America/St_Johns">(GMT -02:30) America/St_Johns</option>
      <option data-offset="-02:30" value="Canada/Newfoundland">(GMT -02:30) Canada/Newfoundland</option>
      <option data-offset="-02:00" value="America/Godthab">(GMT -02:00) America/Godthab</option>
      <option data-offset="-02:00" value="America/Miquelon">(GMT -02:00) America/Miquelon</option>
      <option data-offset="-02:00" value="America/Noronha">(GMT -02:00) America/Noronha</option>
      <option data-offset="-02:00" value="Atlantic/South_Georgia">(GMT -02:00) Atlantic/South_Georgia</option>
      <option data-offset="-02:00" value="Brazil/DeNoronha">(GMT -02:00) Brazil/DeNoronha</option>
      <option data-offset="-02:00" value="Etc/GMT+2">(GMT -02:00) Etc/GMT+2</option>
      <option data-offset="-01:00" value="Atlantic/Cape_Verde">(GMT -01:00) Atlantic/Cape_Verde</option>
      <option data-offset="-01:00" value="Etc/GMT+1">(GMT -01:00) Etc/GMT+1</option>
      <option data-offset="+00:00" value="Africa/Abidjan">(GMT +00:00) Africa/Abidjan</option>
      <option data-offset="+00:00" value="Africa/Accra">(GMT +00:00) Africa/Accra</option>
      <option data-offset="+00:00" value="Africa/Bamako">(GMT +00:00) Africa/Bamako</option>
      <option data-offset="+00:00" value="Africa/Banjul">(GMT +00:00) Africa/Banjul</option>
      <option data-offset="+00:00" value="Africa/Bissau">(GMT +00:00) Africa/Bissau</option>
      <option data-offset="+00:00" value="Africa/Conakry">(GMT +00:00) Africa/Conakry</option>
      <option data-offset="+00:00" value="Africa/Dakar">(GMT +00:00) Africa/Dakar</option>
      <option data-offset="+00:00" value="Africa/Freetown">(GMT +00:00) Africa/Freetown</option>
      <option data-offset="+00:00" value="Africa/Lome">(GMT +00:00) Africa/Lome</option>
      <option data-offset="+00:00" value="Africa/Monrovia">(GMT +00:00) Africa/Monrovia</option>
      <option data-offset="+00:00" value="Africa/Nouakchott">(GMT +00:00) Africa/Nouakchott</option>
      <option data-offset="+00:00" value="Africa/Ouagadougou">(GMT +00:00) Africa/Ouagadougou</option>
      <option data-offset="+00:00" value="Africa/Sao_Tome">(GMT +00:00) Africa/Sao_Tome</option>
      <option data-offset="+00:00" value="Africa/Timbuktu">(GMT +00:00) Africa/Timbuktu</option>
      <option data-offset="+00:00" value="America/Danmarkshavn">(GMT +00:00) America/Danmarkshavn</option>
      <option data-offset="+00:00" value="America/Scoresbysund">(GMT +00:00) America/Scoresbysund</option>
      <option data-offset="+00:00" value="Atlantic/Azores">(GMT +00:00) Atlantic/Azores</option>
      <option data-offset="+00:00" value="Atlantic/Reykjavik">(GMT +00:00) Atlantic/Reykjavik</option>
      <option data-offset="+00:00" value="Atlantic/St_Helena">(GMT +00:00) Atlantic/St_Helena</option>
      <option data-offset="+00:00" value="Etc/GMT">(GMT +00:00) Etc/GMT</option>
      <option data-offset="+00:00" value="Etc/GMT+0">(GMT +00:00) Etc/GMT+0</option>
      <option data-offset="+00:00" value="Etc/GMT-0">(GMT +00:00) Etc/GMT-0</option>
      <option data-offset="+00:00" value="Etc/GMT0">(GMT +00:00) Etc/GMT0</option>
      <option data-offset="+00:00" value="Etc/Greenwich">(GMT +00:00) Etc/Greenwich</option>
      <option data-offset="+00:00" value="Etc/UCT">(GMT +00:00) Etc/UCT</option>
      <option data-offset="+00:00" value="Etc/UTC">(GMT +00:00) Etc/UTC</option>
      <option data-offset="+00:00" value="Etc/Universal">(GMT +00:00) Etc/Universal</option>
      <option data-offset="+00:00" value="Etc/Zulu">(GMT +00:00) Etc/Zulu</option>
      <option data-offset="+00:00" value="GMT">(GMT +00:00) GMT</option>
      <option data-offset="+00:00" value="GMT+0">(GMT +00:00) GMT+0</option>
      <option data-offset="+00:00" value="GMT-0">(GMT +00:00) GMT-0</option>
      <option data-offset="+00:00" value="GMT0">(GMT +00:00) GMT0</option>
      <option data-offset="+00:00" value="Greenwich">(GMT +00:00) Greenwich</option>
      <option data-offset="+00:00" value="Iceland">(GMT +00:00) Iceland</option>
      <option data-offset="+00:00" value="UCT">(GMT +00:00) UCT</option>
      <option data-offset="+00:00" value="UTC">(GMT +00:00) UTC</option>
      <option data-offset="+00:00" value="Universal">(GMT +00:00) Universal</option>
      <option data-offset="+00:00" value="Zulu">(GMT +00:00) Zulu</option>
      <option data-offset="+01:00" value="Africa/Algiers">(GMT +01:00) Africa/Algiers</option>
      <option data-offset="+01:00" value="Africa/Bangui">(GMT +01:00) Africa/Bangui</option>
      <option data-offset="+01:00" value="Africa/Brazzaville">(GMT +01:00) Africa/Brazzaville</option>
      <option data-offset="+01:00" value="Africa/Casablanca">(GMT +01:00) Africa/Casablanca</option>
      <option data-offset="+01:00" value="Africa/Douala">(GMT +01:00) Africa/Douala</option>
      <option data-offset="+01:00" value="Africa/El_Aaiun">(GMT +01:00) Africa/El_Aaiun</option>
      <option data-offset="+01:00" value="Africa/Kinshasa">(GMT +01:00) Africa/Kinshasa</option>
      <option data-offset="+01:00" value="Africa/Lagos">(GMT +01:00) Africa/Lagos</option>
      <option data-offset="+01:00" value="Africa/Libreville">(GMT +01:00) Africa/Libreville</option>
      <option data-offset="+01:00" value="Africa/Luanda">(GMT +01:00) Africa/Luanda</option>
      <option data-offset="+01:00" value="Africa/Malabo">(GMT +01:00) Africa/Malabo</option>
      <option data-offset="+01:00" value="Africa/Ndjamena">(GMT +01:00) Africa/Ndjamena</option>
      <option data-offset="+01:00" value="Africa/Niamey">(GMT +01:00) Africa/Niamey</option>
      <option data-offset="+01:00" value="Africa/Porto-Novo">(GMT +01:00) Africa/Porto-Novo</option>
      <option data-offset="+01:00" value="Africa/Tunis">(GMT +01:00) Africa/Tunis</option>
      <option data-offset="+01:00" value="Africa/Windhoek">(GMT +01:00) Africa/Windhoek</option>
      <option data-offset="+01:00" value="Atlantic/Canary">(GMT +01:00) Atlantic/Canary</option>
      <option data-offset="+01:00" value="Atlantic/Faeroe">(GMT +01:00) Atlantic/Faeroe</option>
      <option data-offset="+01:00" value="Atlantic/Faroe">(GMT +01:00) Atlantic/Faroe</option>
      <option data-offset="+01:00" value="Atlantic/Madeira">(GMT +01:00) Atlantic/Madeira</option>
      <option data-offset="+01:00" value="Eire">(GMT +01:00) Eire</option>
      <option data-offset="+01:00" value="Etc/GMT-1">(GMT +01:00) Etc/GMT-1</option>
      <option data-offset="+01:00" value="Europe/Belfast">(GMT +01:00) Europe/Belfast</option>
      <option data-offset="+01:00" value="Europe/Dublin">(GMT +01:00) Europe/Dublin</option>
      <option data-offset="+01:00" value="Europe/Guernsey">(GMT +01:00) Europe/Guernsey</option>
      <option data-offset="+01:00" value="Europe/Isle_of_Man">(GMT +01:00) Europe/Isle_of_Man</option>
      <option data-offset="+01:00" value="Europe/Jersey">(GMT +01:00) Europe/Jersey</option>
      <option data-offset="+01:00" value="Europe/Lisbon">(GMT +01:00) Europe/Lisbon</option>
      <option data-offset="+01:00" value="Europe/London">(GMT +01:00) Europe/London</option>
      <option data-offset="+01:00" value="GB">(GMT +01:00) GB</option>
      <option data-offset="+01:00" value="GB-Eire">(GMT +01:00) GB-Eire</option>
      <option data-offset="+01:00" value="Portugal">(GMT +01:00) Portugal</option>
      <option data-offset="+01:00" value="WET">(GMT +01:00) WET</option>
      <option data-offset="+02:00" value="Africa/Blantyre">(GMT +02:00) Africa/Blantyre</option>
      <option data-offset="+02:00" value="Africa/Bujumbura">(GMT +02:00) Africa/Bujumbura</option>
      <option data-offset="+02:00" value="Africa/Ceuta">(GMT +02:00) Africa/Ceuta</option>
      <option data-offset="+02:00" value="Africa/Gaborone">(GMT +02:00) Africa/Gaborone</option>
      <option data-offset="+02:00" value="Africa/Harare">(GMT +02:00) Africa/Harare</option>
      <option data-offset="+02:00" value="Africa/Johannesburg">(GMT +02:00) Africa/Johannesburg</option>
      <option data-offset="+02:00" value="Africa/Kigali">(GMT +02:00) Africa/Kigali</option>
      <option data-offset="+02:00" value="Africa/Lubumbashi">(GMT +02:00) Africa/Lubumbashi</option>
      <option data-offset="+02:00" value="Africa/Lusaka">(GMT +02:00) Africa/Lusaka</option>
      <option data-offset="+02:00" value="Africa/Maputo">(GMT +02:00) Africa/Maputo</option>
      <option data-offset="+02:00" value="Africa/Maseru">(GMT +02:00) Africa/Maseru</option>
      <option data-offset="+02:00" value="Africa/Mbabane">(GMT +02:00) Africa/Mbabane</option>
      <option data-offset="+02:00" value="Africa/Tripoli">(GMT +02:00) Africa/Tripoli</option>
      <option data-offset="+02:00" value="Antarctica/Troll">(GMT +02:00) Antarctica/Troll</option>
      <option data-offset="+02:00" value="Arctic/Longyearbyen">(GMT +02:00) Arctic/Longyearbyen</option>
      <option data-offset="+02:00" value="Atlantic/Jan_Mayen">(GMT +02:00) Atlantic/Jan_Mayen</option>
      <option data-offset="+02:00" value="CET">(GMT +02:00) CET</option>
      <option data-offset="+02:00" value="Etc/GMT-2">(GMT +02:00) Etc/GMT-2</option>
      <option data-offset="+02:00" value="Europe/Amsterdam">(GMT +02:00) Europe/Amsterdam</option>
      <option data-offset="+02:00" value="Europe/Andorra">(GMT +02:00) Europe/Andorra</option>
      <option data-offset="+02:00" value="Europe/Belgrade">(GMT +02:00) Europe/Belgrade</option>
      <option data-offset="+02:00" value="Europe/Berlin">(GMT +02:00) Europe/Berlin</option>
      <option data-offset="+02:00" value="Europe/Bratislava">(GMT +02:00) Europe/Bratislava</option>
      <option data-offset="+02:00" value="Europe/Brussels">(GMT +02:00) Europe/Brussels</option>
      <option data-offset="+02:00" value="Europe/Budapest">(GMT +02:00) Europe/Budapest</option>
      <option data-offset="+02:00" value="Europe/Busingen">(GMT +02:00) Europe/Busingen</option>
      <option data-offset="+02:00" value="Europe/Copenhagen">(GMT +02:00) Europe/Copenhagen</option>
      <option data-offset="+02:00" value="Europe/Gibraltar">(GMT +02:00) Europe/Gibraltar</option>
      <option data-offset="+02:00" value="Europe/Kaliningrad">(GMT +02:00) Europe/Kaliningrad</option>
      <option data-offset="+02:00" value="Europe/Ljubljana">(GMT +02:00) Europe/Ljubljana</option>
      <option data-offset="+02:00" value="Europe/Luxembourg">(GMT +02:00) Europe/Luxembourg</option>
      <option data-offset="+02:00" value="Europe/Madrid">(GMT +02:00) Europe/Madrid</option>
      <option data-offset="+02:00" value="Europe/Malta">(GMT +02:00) Europe/Malta</option>
      <option data-offset="+02:00" value="Europe/Monaco">(GMT +02:00) Europe/Monaco</option>
      <option data-offset="+02:00" value="Europe/Oslo">(GMT +02:00) Europe/Oslo</option>
      <option data-offset="+02:00" value="Europe/Paris">(GMT +02:00) Europe/Paris</option>
      <option data-offset="+02:00" value="Europe/Podgorica">(GMT +02:00) Europe/Podgorica</option>
      <option data-offset="+02:00" value="Europe/Prague">(GMT +02:00) Europe/Prague</option>
      <option data-offset="+02:00" value="Europe/Rome">(GMT +02:00) Europe/Rome</option>
      <option data-offset="+02:00" value="Europe/San_Marino">(GMT +02:00) Europe/San_Marino</option>
      <option data-offset="+02:00" value="Europe/Sarajevo">(GMT +02:00) Europe/Sarajevo</option>
      <option data-offset="+02:00" value="Europe/Skopje">(GMT +02:00) Europe/Skopje</option>
      <option data-offset="+02:00" value="Europe/Stockholm">(GMT +02:00) Europe/Stockholm</option>
      <option data-offset="+02:00" value="Europe/Tirane">(GMT +02:00) Europe/Tirane</option>
      <option data-offset="+02:00" value="Europe/Vaduz">(GMT +02:00) Europe/Vaduz</option>
      <option data-offset="+02:00" value="Europe/Vatican">(GMT +02:00) Europe/Vatican</option>
      <option data-offset="+02:00" value="Europe/Vienna">(GMT +02:00) Europe/Vienna</option>
      <option data-offset="+02:00" value="Europe/Warsaw">(GMT +02:00) Europe/Warsaw</option>
      <option data-offset="+02:00" value="Europe/Zagreb">(GMT +02:00) Europe/Zagreb</option>
      <option data-offset="+02:00" value="Europe/Zurich">(GMT +02:00) Europe/Zurich</option>
      <option data-offset="+02:00" value="Libya">(GMT +02:00) Libya</option>
      <option data-offset="+02:00" value="MET">(GMT +02:00) MET</option>
      <option data-offset="+02:00" value="Poland">(GMT +02:00) Poland</option>
      <option data-offset="+03:00" value="Africa/Addis_Ababa">(GMT +03:00) Africa/Addis_Ababa</option>
      <option data-offset="+03:00" value="Africa/Asmara">(GMT +03:00) Africa/Asmara</option>
      <option data-offset="+03:00" value="Africa/Asmera">(GMT +03:00) Africa/Asmera</option>
      <option data-offset="+03:00" value="Africa/Cairo">(GMT +03:00) Africa/Cairo</option>
      <option data-offset="+03:00" value="Africa/Dar_es_Salaam">(GMT +03:00) Africa/Dar_es_Salaam</option>
      <option data-offset="+03:00" value="Africa/Djibouti">(GMT +03:00) Africa/Djibouti</option>
      <option data-offset="+03:00" value="Africa/Juba">(GMT +03:00) Africa/Juba</option>
      <option data-offset="+03:00" value="Africa/Kampala">(GMT +03:00) Africa/Kampala</option>
      <option data-offset="+03:00" value="Africa/Khartoum">(GMT +03:00) Africa/Khartoum</option>
      <option data-offset="+03:00" value="Africa/Mogadishu">(GMT +03:00) Africa/Mogadishu</option>
      <option data-offset="+03:00" value="Africa/Nairobi">(GMT +03:00) Africa/Nairobi</option>
      <option data-offset="+03:00" value="Antarctica/Syowa">(GMT +03:00) Antarctica/Syowa</option>
      <option data-offset="+03:00" value="Asia/Aden">(GMT +03:00) Asia/Aden</option>
      <option data-offset="+03:00" value="Asia/Amman">(GMT +03:00) Asia/Amman</option>
      <option data-offset="+03:00" value="Asia/Baghdad">(GMT +03:00) Asia/Baghdad</option>
      <option data-offset="+03:00" value="Asia/Bahrain">(GMT +03:00) Asia/Bahrain</option>
      <option data-offset="+03:00" value="Asia/Beirut">(GMT +03:00) Asia/Beirut</option>
      <option data-offset="+03:00" value="Asia/Damascus">(GMT +03:00) Asia/Damascus</option>
      <option data-offset="+03:00" value="Asia/Gaza">(GMT +03:00) Asia/Gaza</option>
      <option data-offset="+03:00" value="Asia/Hebron">(GMT +03:00) Asia/Hebron</option>
      <option data-offset="+03:00" value="Asia/Istanbul">(GMT +03:00) Asia/Istanbul</option>
      <option data-offset="+03:00" value="Asia/Jerusalem">(GMT +03:00) Asia/Jerusalem</option>
      <option data-offset="+03:00" value="Asia/Kuwait">(GMT +03:00) Asia/Kuwait</option>
      <option data-offset="+03:00" value="Asia/Nicosia">(GMT +03:00) Asia/Nicosia</option>
      <option data-offset="+03:00" value="Asia/Qatar">(GMT +03:00) Asia/Qatar</option>
      <option data-offset="+03:00" value="Asia/Riyadh">(GMT +03:00) Asia/Riyadh</option>
      <option data-offset="+03:00" value="Asia/Tel_Aviv">(GMT +03:00) Asia/Tel_Aviv</option>
      <option data-offset="+03:00" value="EET">(GMT +03:00) EET</option>
      <option data-offset="+03:00" value="Egypt">(GMT +03:00) Egypt</option>
      <option data-offset="+03:00" value="Etc/GMT-3">(GMT +03:00) Etc/GMT-3</option>
      <option data-offset="+03:00" value="Europe/Athens">(GMT +03:00) Europe/Athens</option>
      <option data-offset="+03:00" value="Europe/Bucharest">(GMT +03:00) Europe/Bucharest</option>
      <option data-offset="+03:00" value="Europe/Chisinau">(GMT +03:00) Europe/Chisinau</option>
      <option data-offset="+03:00" value="Europe/Helsinki">(GMT +03:00) Europe/Helsinki</option>
      <option data-offset="+03:00" value="Europe/Istanbul">(GMT +03:00) Europe/Istanbul</option>
      <option data-offset="+03:00" value="Europe/Kiev">(GMT +03:00) Europe/Kiev</option>
      <option data-offset="+03:00" value="Europe/Mariehamn">(GMT +03:00) Europe/Mariehamn</option>
      <option data-offset="+03:00" value="Europe/Minsk">(GMT +03:00) Europe/Minsk</option>
      <option data-offset="+03:00" value="Europe/Moscow">(GMT +03:00) Europe/Moscow</option>
      <option data-offset="+03:00" value="Europe/Nicosia">(GMT +03:00) Europe/Nicosia</option>
      <option data-offset="+03:00" value="Europe/Riga">(GMT +03:00) Europe/Riga</option>
      <option data-offset="+03:00" value="Europe/Simferopol">(GMT +03:00) Europe/Simferopol</option>
      <option data-offset="+03:00" value="Europe/Sofia">(GMT +03:00) Europe/Sofia</option>
      <option data-offset="+03:00" value="Europe/Tallinn">(GMT +03:00) Europe/Tallinn</option>
      <option data-offset="+03:00" value="Europe/Tiraspol">(GMT +03:00) Europe/Tiraspol</option>
      <option data-offset="+03:00" value="Europe/Uzhgorod">(GMT +03:00) Europe/Uzhgorod</option>
      <option data-offset="+03:00" value="Europe/Vilnius">(GMT +03:00) Europe/Vilnius</option>
      <option data-offset="+03:00" value="Europe/Volgograd">(GMT +03:00) Europe/Volgograd</option>
      <option data-offset="+03:00" value="Europe/Zaporozhye">(GMT +03:00) Europe/Zaporozhye</option>
      <option data-offset="+03:00" value="Indian/Antananarivo">(GMT +03:00) Indian/Antananarivo</option>
      <option data-offset="+03:00" value="Indian/Comoro">(GMT +03:00) Indian/Comoro</option>
      <option data-offset="+03:00" value="Indian/Mayotte">(GMT +03:00) Indian/Mayotte</option>
      <option data-offset="+03:00" value="Israel">(GMT +03:00) Israel</option>
      <option data-offset="+03:00" value="Turkey">(GMT +03:00) Turkey</option>
      <option data-offset="+03:00" value="W-SU">(GMT +03:00) W-SU</option>
      <option data-offset="+04:00" value="Asia/Dubai">(GMT +04:00) Asia/Dubai</option>
      <option data-offset="+04:00" value="Asia/Muscat">(GMT +04:00) Asia/Muscat</option>
      <option data-offset="+04:00" value="Asia/Tbilisi">(GMT +04:00) Asia/Tbilisi</option>
      <option data-offset="+04:00" value="Asia/Yerevan">(GMT +04:00) Asia/Yerevan</option>
      <option data-offset="+04:00" value="Etc/GMT-4">(GMT +04:00) Etc/GMT-4</option>
      <option data-offset="+04:00" value="Europe/Samara">(GMT +04:00) Europe/Samara</option>
      <option data-offset="+04:00" value="Indian/Mahe">(GMT +04:00) Indian/Mahe</option>
      <option data-offset="+04:00" value="Indian/Mauritius">(GMT +04:00) Indian/Mauritius</option>
      <option data-offset="+04:00" value="Indian/Reunion">(GMT +04:00) Indian/Reunion</option>
      <option data-offset="+04:30" value="Asia/Kabul">(GMT +04:30) Asia/Kabul</option>
      <option data-offset="+04:30" value="Asia/Tehran">(GMT +04:30) Asia/Tehran</option>
      <option data-offset="+04:30" value="Iran">(GMT +04:30) Iran</option>
      <option data-offset="+05:00" value="Antarctica/Mawson">(GMT +05:00) Antarctica/Mawson</option>
      <option data-offset="+05:00" value="Asia/Aqtau">(GMT +05:00) Asia/Aqtau</option>
      <option data-offset="+05:00" value="Asia/Aqtobe">(GMT +05:00) Asia/Aqtobe</option>
      <option data-offset="+05:00" value="Asia/Ashgabat">(GMT +05:00) Asia/Ashgabat</option>
      <option data-offset="+05:00" value="Asia/Ashkhabad">(GMT +05:00) Asia/Ashkhabad</option>
      <option data-offset="+05:00" value="Asia/Baku">(GMT +05:00) Asia/Baku</option>
      <option data-offset="+05:00" value="Asia/Dushanbe">(GMT +05:00) Asia/Dushanbe</option>
      <option data-offset="+05:00" value="Asia/Karachi">(GMT +05:00) Asia/Karachi</option>
      <option data-offset="+05:00" value="Asia/Oral">(GMT +05:00) Asia/Oral</option>
      <option data-offset="+05:00" value="Asia/Samarkand">(GMT +05:00) Asia/Samarkand</option>
      <option data-offset="+05:00" value="Asia/Tashkent">(GMT +05:00) Asia/Tashkent</option>
      <option data-offset="+05:00" value="Asia/Yekaterinburg">(GMT +05:00) Asia/Yekaterinburg</option>
      <option data-offset="+05:00" value="Etc/GMT-5">(GMT +05:00) Etc/GMT-5</option>
      <option data-offset="+05:00" value="Indian/Kerguelen">(GMT +05:00) Indian/Kerguelen</option>
      <option data-offset="+05:00" value="Indian/Maldives">(GMT +05:00) Indian/Maldives</option>
      <option data-offset="+05:30" value="Asia/Calcutta">(GMT +05:30) Asia/Calcutta</option>
      <option data-offset="+05:30" value="Asia/Colombo">(GMT +05:30) Asia/Colombo</option>
      <option data-offset="+05:30" value="Asia/Kolkata">(GMT +05:30) Asia/Kolkata</option>
      <option data-offset="+05:45" value="Asia/Kathmandu">(GMT +05:45) Asia/Kathmandu</option>
      <option data-offset="+05:45" value="Asia/Katmandu">(GMT +05:45) Asia/Katmandu</option>
      <option data-offset="+06:00" value="Antarctica/Vostok">(GMT +06:00) Antarctica/Vostok</option>
      <option data-offset="+06:00" value="Asia/Almaty">(GMT +06:00) Asia/Almaty</option>
      <option data-offset="+06:00" value="Asia/Bishkek">(GMT +06:00) Asia/Bishkek</option>
      <option data-offset="+06:00" value="Asia/Dacca">(GMT +06:00) Asia/Dacca</option>
      <option data-offset="+06:00" value="Asia/Dhaka">(GMT +06:00) Asia/Dhaka</option>
      <option data-offset="+06:00" value="Asia/Kashgar">(GMT +06:00) Asia/Kashgar</option>
      <option data-offset="+06:00" value="Asia/Novosibirsk">(GMT +06:00) Asia/Novosibirsk</option>
      <option data-offset="+06:00" value="Asia/Omsk">(GMT +06:00) Asia/Omsk</option>
      <option data-offset="+06:00" value="Asia/Qyzylorda">(GMT +06:00) Asia/Qyzylorda</option>
      <option data-offset="+06:00" value="Asia/Thimbu">(GMT +06:00) Asia/Thimbu</option>
      <option data-offset="+06:00" value="Asia/Thimphu">(GMT +06:00) Asia/Thimphu</option>
      <option data-offset="+06:00" value="Asia/Urumqi">(GMT +06:00) Asia/Urumqi</option>
      <option data-offset="+06:00" value="Etc/GMT-6">(GMT +06:00) Etc/GMT-6</option>
      <option data-offset="+06:00" value="Indian/Chagos">(GMT +06:00) Indian/Chagos</option>
      <option data-offset="+06:30" value="Asia/Rangoon">(GMT +06:30) Asia/Rangoon</option>
      <option data-offset="+06:30" value="Indian/Cocos">(GMT +06:30) Indian/Cocos</option>
      <option data-offset="+07:00" value="Antarctica/Davis">(GMT +07:00) Antarctica/Davis</option>
      <option data-offset="+07:00" value="Asia/Bangkok">(GMT +07:00) Asia/Bangkok</option>
      <option data-offset="+07:00" value="Asia/Ho_Chi_Minh">(GMT +07:00) Asia/Ho_Chi_Minh</option>
      <option data-offset="+07:00" value="Asia/Hovd">(GMT +07:00) Asia/Hovd</option>
      <option data-offset="+07:00" value="Asia/Jakarta">(GMT +07:00) Asia/Jakarta</option>
      <option data-offset="+07:00" value="Asia/Krasnoyarsk">(GMT +07:00) Asia/Krasnoyarsk</option>
      <option data-offset="+07:00" value="Asia/Novokuznetsk">(GMT +07:00) Asia/Novokuznetsk</option>
      <option data-offset="+07:00" value="Asia/Phnom_Penh">(GMT +07:00) Asia/Phnom_Penh</option>
      <option data-offset="+07:00" value="Asia/Pontianak">(GMT +07:00) Asia/Pontianak</option>
      <option data-offset="+07:00" value="Asia/Saigon">(GMT +07:00) Asia/Saigon</option>
      <option data-offset="+07:00" value="Asia/Vientiane">(GMT +07:00) Asia/Vientiane</option>
      <option data-offset="+07:00" value="Etc/GMT-7">(GMT +07:00) Etc/GMT-7</option>
      <option data-offset="+07:00" value="Indian/Christmas">(GMT +07:00) Indian/Christmas</option>
      <option data-offset="+08:00" value="Antarctica/Casey">(GMT +08:00) Antarctica/Casey</option>
      <option data-offset="+08:00" value="Asia/Brunei">(GMT +08:00) Asia/Brunei</option>
      <option data-offset="+08:00" value="Asia/Chita">(GMT +08:00) Asia/Chita</option>
      <option data-offset="+08:00" value="Asia/Choibalsan">(GMT +08:00) Asia/Choibalsan</option>
      <option data-offset="+08:00" value="Asia/Chongqing">(GMT +08:00) Asia/Chongqing</option>
      <option data-offset="+08:00" value="Asia/Chungking">(GMT +08:00) Asia/Chungking</option>
      <option data-offset="+08:00" value="Asia/Harbin">(GMT +08:00) Asia/Harbin</option>
      <option data-offset="+08:00" value="Asia/Hong_Kong">(GMT +08:00) Asia/Hong_Kong</option>
      <option data-offset="+08:00" value="Asia/Irkutsk">(GMT +08:00) Asia/Irkutsk</option>
      <option data-offset="+08:00" value="Asia/Kuala_Lumpur">(GMT +08:00) Asia/Kuala_Lumpur</option>
      <option data-offset="+08:00" value="Asia/Kuching">(GMT +08:00) Asia/Kuching</option>
      <option data-offset="+08:00" value="Asia/Macao">(GMT +08:00) Asia/Macao</option>
      <option data-offset="+08:00" value="Asia/Macau">(GMT +08:00) Asia/Macau</option>
      <option data-offset="+08:00" value="Asia/Makassar">(GMT +08:00) Asia/Makassar</option>
      <option data-offset="+08:00" value="Asia/Manila">(GMT +08:00) Asia/Manila</option>
      <option data-offset="+08:00" value="Asia/Shanghai">(GMT +08:00) Asia/Shanghai</option>
      <option data-offset="+08:00" value="Asia/Singapore">(GMT +08:00) Asia/Singapore</option>
      <option data-offset="+08:00" value="Asia/Taipei">(GMT +08:00) Asia/Taipei</option>
      <option data-offset="+08:00" value="Asia/Ujung_Pandang">(GMT +08:00) Asia/Ujung_Pandang</option>
      <option data-offset="+08:00" value="Asia/Ulaanbaatar">(GMT +08:00) Asia/Ulaanbaatar</option>
      <option data-offset="+08:00" value="Asia/Ulan_Bator">(GMT +08:00) Asia/Ulan_Bator</option>
      <option data-offset="+08:00" value="Australia/Perth">(GMT +08:00) Australia/Perth</option>
      <option data-offset="+08:00" value="Australia/West">(GMT +08:00) Australia/West</option>
      <option data-offset="+08:00" value="Etc/GMT-8">(GMT +08:00) Etc/GMT-8</option>
      <option data-offset="+08:00" value="Hongkong">(GMT +08:00) Hongkong</option>
      <option data-offset="+08:00" value="PRC">(GMT +08:00) PRC</option>
      <option data-offset="+08:00" value="ROC">(GMT +08:00) ROC</option>
      <option data-offset="+08:00" value="Singapore">(GMT +08:00) Singapore</option>
      <option data-offset="+08:45" value="Australia/Eucla">(GMT +08:45) Australia/Eucla</option>
      <option data-offset="+09:00" value="Asia/Dili">(GMT +09:00) Asia/Dili</option>
      <option data-offset="+09:00" value="Asia/Jayapura">(GMT +09:00) Asia/Jayapura</option>
      <option data-offset="+09:00" value="Asia/Khandyga">(GMT +09:00) Asia/Khandyga</option>
      <option data-offset="+09:00" value="Asia/Pyongyang">(GMT +09:00) Asia/Pyongyang</option>
      <option data-offset="+09:00" value="Asia/Seoul">(GMT +09:00) Asia/Seoul</option>
      <option data-offset="+09:00" value="Asia/Tokyo">(GMT +09:00) Asia/Tokyo</option>
      <option data-offset="+09:00" value="Asia/Yakutsk">(GMT +09:00) Asia/Yakutsk</option>
      <option data-offset="+09:00" value="Etc/GMT-9">(GMT +09:00) Etc/GMT-9</option>
      <option data-offset="+09:00" value="Japan">(GMT +09:00) Japan</option>
      <option data-offset="+09:00" value="Pacific/Palau">(GMT +09:00) Pacific/Palau</option>
      <option data-offset="+09:00" value="ROK">(GMT +09:00) ROK</option>
      <option data-offset="+09:30" value="Australia/Adelaide">(GMT +09:30) Australia/Adelaide</option>
      <option data-offset="+09:30" value="Australia/Broken_Hill">(GMT +09:30) Australia/Broken_Hill</option>
      <option data-offset="+09:30" value="Australia/Darwin">(GMT +09:30) Australia/Darwin</option>
      <option data-offset="+09:30" value="Australia/North">(GMT +09:30) Australia/North</option>
      <option data-offset="+09:30" value="Australia/South">(GMT +09:30) Australia/South</option>
      <option data-offset="+09:30" value="Australia/Yancowinna">(GMT +09:30) Australia/Yancowinna</option>
      <option data-offset="+10:00" value="Antarctica/DumontDUrville">(GMT +10:00) Antarctica/DumontDUrville</option>
      <option data-offset="+10:00" value="Asia/Magadan">(GMT +10:00) Asia/Magadan</option>
      <option data-offset="+10:00" value="Asia/Sakhalin">(GMT +10:00) Asia/Sakhalin</option>
      <option data-offset="+10:00" value="Asia/Ust-Nera">(GMT +10:00) Asia/Ust-Nera</option>
      <option data-offset="+10:00" value="Asia/Vladivostok">(GMT +10:00) Asia/Vladivostok</option>
      <option data-offset="+10:00" value="Australia/ACT">(GMT +10:00) Australia/ACT</option>
      <option data-offset="+10:00" value="Australia/Brisbane">(GMT +10:00) Australia/Brisbane</option>
      <option data-offset="+10:00" value="Australia/Canberra">(GMT +10:00) Australia/Canberra</option>
      <option data-offset="+10:00" value="Australia/Currie">(GMT +10:00) Australia/Currie</option>
      <option data-offset="+10:00" value="Australia/Hobart">(GMT +10:00) Australia/Hobart</option>
      <option data-offset="+10:00" value="Australia/Lindeman">(GMT +10:00) Australia/Lindeman</option>
      <option data-offset="+10:00" value="Australia/Melbourne">(GMT +10:00) Australia/Melbourne</option>
      <option data-offset="+10:00" value="Australia/NSW">(GMT +10:00) Australia/NSW</option>
      <option data-offset="+10:00" value="Australia/Queensland">(GMT +10:00) Australia/Queensland</option>
      <option data-offset="+10:00" value="Australia/Sydney">(GMT +10:00) Australia/Sydney</option>
      <option data-offset="+10:00" value="Australia/Tasmania">(GMT +10:00) Australia/Tasmania</option>
      <option data-offset="+10:00" value="Australia/Victoria">(GMT +10:00) Australia/Victoria</option>
      <option data-offset="+10:00" value="Etc/GMT-10">(GMT +10:00) Etc/GMT-10</option>
      <option data-offset="+10:00" value="Pacific/Chuuk">(GMT +10:00) Pacific/Chuuk</option>
      <option data-offset="+10:00" value="Pacific/Guam">(GMT +10:00) Pacific/Guam</option>
      <option data-offset="+10:00" value="Pacific/Port_Moresby">(GMT +10:00) Pacific/Port_Moresby</option>
      <option data-offset="+10:00" value="Pacific/Saipan">(GMT +10:00) Pacific/Saipan</option>
      <option data-offset="+10:00" value="Pacific/Truk">(GMT +10:00) Pacific/Truk</option>
      <option data-offset="+10:00" value="Pacific/Yap">(GMT +10:00) Pacific/Yap</option>
      <option data-offset="+10:30" value="Australia/LHI">(GMT +10:30) Australia/LHI</option>
      <option data-offset="+10:30" value="Australia/Lord_Howe">(GMT +10:30) Australia/Lord_Howe</option>
      <option data-offset="+11:00" value="Antarctica/Macquarie">(GMT +11:00) Antarctica/Macquarie</option>
      <option data-offset="+11:00" value="Asia/Srednekolymsk">(GMT +11:00) Asia/Srednekolymsk</option>
      <option data-offset="+11:00" value="Etc/GMT-11">(GMT +11:00) Etc/GMT-11</option>
      <option data-offset="+11:00" value="Pacific/Bougainville">(GMT +11:00) Pacific/Bougainville</option>
      <option data-offset="+11:00" value="Pacific/Efate">(GMT +11:00) Pacific/Efate</option>
      <option data-offset="+11:00" value="Pacific/Guadalcanal">(GMT +11:00) Pacific/Guadalcanal</option>
      <option data-offset="+11:00" value="Pacific/Kosrae">(GMT +11:00) Pacific/Kosrae</option>
      <option data-offset="+11:00" value="Pacific/Noumea">(GMT +11:00) Pacific/Noumea</option>
      <option data-offset="+11:00" value="Pacific/Pohnpei">(GMT +11:00) Pacific/Pohnpei</option>
      <option data-offset="+11:00" value="Pacific/Ponape">(GMT +11:00) Pacific/Ponape</option>
      <option data-offset="+11:30" value="Pacific/Norfolk">(GMT +11:30) Pacific/Norfolk</option>
      <option data-offset="+12:00" value="Antarctica/McMurdo">(GMT +12:00) Antarctica/McMurdo</option>
      <option data-offset="+12:00" value="Antarctica/South_Pole">(GMT +12:00) Antarctica/South_Pole</option>
      <option data-offset="+12:00" value="Asia/Anadyr">(GMT +12:00) Asia/Anadyr</option>
      <option data-offset="+12:00" value="Asia/Kamchatka">(GMT +12:00) Asia/Kamchatka</option>
      <option data-offset="+12:00" value="Etc/GMT-12">(GMT +12:00) Etc/GMT-12</option>
      <option data-offset="+12:00" value="Kwajalein">(GMT +12:00) Kwajalein</option>
      <option data-offset="+12:00" value="NZ">(GMT +12:00) NZ</option>
      <option data-offset="+12:00" value="Pacific/Auckland">(GMT +12:00) Pacific/Auckland</option>
      <option data-offset="+12:00" value="Pacific/Fiji">(GMT +12:00) Pacific/Fiji</option>
      <option data-offset="+12:00" value="Pacific/Funafuti">(GMT +12:00) Pacific/Funafuti</option>
      <option data-offset="+12:00" value="Pacific/Kwajalein">(GMT +12:00) Pacific/Kwajalein</option>
      <option data-offset="+12:00" value="Pacific/Majuro">(GMT +12:00) Pacific/Majuro</option>
      <option data-offset="+12:00" value="Pacific/Nauru">(GMT +12:00) Pacific/Nauru</option>
      <option data-offset="+12:00" value="Pacific/Tarawa">(GMT +12:00) Pacific/Tarawa</option>
      <option data-offset="+12:00" value="Pacific/Wake">(GMT +12:00) Pacific/Wake</option>
      <option data-offset="+12:00" value="Pacific/Wallis">(GMT +12:00) Pacific/Wallis</option>
      <option data-offset="+12:45" value="NZ-CHAT">(GMT +12:45) NZ-CHAT</option>
      <option data-offset="+12:45" value="Pacific/Chatham">(GMT +12:45) Pacific/Chatham</option>
      <option data-offset="+13:00" value="Etc/GMT-13">(GMT +13:00) Etc/GMT-13</option>
      <option data-offset="+13:00" value="Pacific/Apia">(GMT +13:00) Pacific/Apia</option>
      <option data-offset="+13:00" value="Pacific/Enderbury">(GMT +13:00) Pacific/Enderbury</option>
      <option data-offset="+13:00" value="Pacific/Fakaofo">(GMT +13:00) Pacific/Fakaofo</option>
      <option data-offset="+13:00" value="Pacific/Tongatapu">(GMT +13:00) Pacific/Tongatapu</option>
      <option data-offset="+14:00" value="Etc/GMT-14">(GMT +14:00) Etc/GMT-14</option>
      <option data-offset="+14:00" value="Pacific/Kiritimati">(GMT +14:00) Pacific/Kiritimati</option>
    </select>
    `;
    /*eslint-enable*/
  };


  const editorDOM = function (id) {
    return `
      <div class="stent-cron-editor" id="${id}">
        <div class="mb-3">
          ${timezonesDOM( id + "-timezone")}
        </div>
        <div class="card">
          <div class="card-body" style="padding:0px;">
            <table class="table table-sm" style="margin-bottom:0px">
              <thead>
                <tr>
                  <th scope="col" style="border-top:none; border-top-left-radius: 10px" width="50">Actions</th>
                  <th scope="col" style="border-top:none; ">Slot</th>
                  <th scope="col" style="border-top:none; border-top-right-radius: 10px"  width="150">Duration</th>
                </tr>
              </thead>
              <tbody>
               <tr id="week-days-wrapper-tr">
                  <td>
                    <span >Days</span>
                  </td>
                  <td colspan="2" id="week-days-wrapper">
                    <span class="mr-3" style="cursor: pointer;">
                      <input type="checkbox" name="weekDays" id="MON" value="MON" style="vertical-align: -2px;" class="pr-1" checked="checked" />
                      <label for="MON" style="margin-bottom: 0;">Mon.</label>
                    </span>
                    <span class="mr-3" style="cursor: pointer;">
                      <input type="checkbox" name="weekDays" id="TUE" value="TUE" style="vertical-align: -2px;" class="pr-1" checked="checked" />
                      <label for="TUE" style="margin-bottom: 0;">Tue.</label>
                    </span>
                    <span class="mr-3" style="cursor: pointer;">
                      <input type="checkbox" name="weekDays" id="WED" value="WED" style="vertical-align: -2px;" class="pr-1" checked="checked" />
                      <label for="WED" style="margin-bottom: 0;">Wed.</label>
                    </span>
                    <span class="mr-3" style="cursor: pointer;">
                      <input type="checkbox" name="weekDays" id="THU" value="THU" style="vertical-align: -2px;" class="pr-1" checked="checked" />
                      <label for="THU" style="margin-bottom: 0;">Thu.</label>
                    </span>
                    <span class="mr-3" style="cursor: pointer;">
                      <input type="checkbox" name="weekDays" id="FRI" value="FRI" style="vertical-align: -2px;" class="pr-1" checked="checked" />
                      <label for="FRI" style="margin-bottom: 0;">Fri.</label>
                    </span>
                    <span class="mr-3" style="cursor: pointer;">
                      <input type="checkbox" name="weekDays" id="SAT" value="SAT" style="vertical-align: -2px;" class="pr-1" checked="checked" />
                      <label for="SAT" style="margin-bottom: 0;">Sat.</label>
                    </span>
                    <span class="mr-3" style="cursor: pointer;">
                      <input type="checkbox" name="weekDays" id="SUN" value="SUN" style="vertical-align: -2px;" class="pr-1" checked="checked" />
                      <label for="SUN" style="margin-bottom: 0;">Sun.</label>
                    </span>
                  </td>
                </tr>
                <tr class="add-new">
                  <td>
                    <span class="btn btn-white fe fe-plus campaign-add-schedule p-0 pb-1 pl-2 pr-2 pt-1"></span>
                  </td>
                  <td style="vertical-align: middle">Add new slot</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    `;
  };


  const cronLineDOM = function(cronObject) {
    /*eslint-disable*/
    return `
      <tr id="${cronObject.id}">
        <td>
          <span class="btn btn-white fe fe-minus campaign-slot-delete p-0 pb-1 pl-2 pr-2 pt-1"></span>
        </td>
        <td style="vertical-align: middle;">
          <div class="campaign-slot-cron-picker"></div>
        </td>
        <td>
          <input 
            type="text" 
            readonly="readonly" 
            style="background-color: white !important;" 
            class="form-control campaign-slot-duration" value="${cronObject.duration ? cronObject.duration : defaultDuration}" />
        </td>
      </tr>
    `;
    /*eslint-enable*/
  };


  const bindEvents = function () {

    $("body")
      .off("click", ".campaign-add-schedule")
      .on("click", ".campaign-add-schedule", function() {

        let editorId = $(this).closest(".stent-cron-editor").attr("id");

        let newCronObject = {
          id: stent.utils.guid(),
          duration: defaultDuration
        };

        // Remove invalid form data
        if ($(this).hasClass("is-invalid")) {
          $(this).removeClass("is-invalid").next().remove();
        }

        $(this)
          .closest("tr")
          .before(cronLineDOM(newCronObject));

        newCronObject.jCron = $("#" + newCronObject.id + " .campaign-slot-cron-picker").cron({
          initial: "0 0 * * *"
        });
        newCronObject.duration = defaultDuration;

        editors[editorId].crons.push(newCronObject);

        $("#" + newCronObject.id + " .campaign-slot-duration").timeDurationPicker(durationPickerSetup());

      });

    $("body")
      .off("click", ".campaign-slot-delete")
      .on("click", ".campaign-slot-delete", function() {
        if (confirm("Please confirm you want to remove this slot.")) {

          let editorId = $(this).closest(".stent-cron-editor").attr("id");

          let removeCronId = $(this)
            .closest("tr")
            .attr("id");

          $(this)
            .closest("tr")
            .remove();

          // remove from crons Object
          for (var i = 0; i < editors[editorId].crons.length; i++) {
            if (editors[editorId].crons[i].id === removeCronId) {
              editors[editorId].crons.splice(i, 1);
            }
          }
        }
      });

    $("body")
      .off("click", ".stent-cron-timezone")
      .on("click", ".stent-cron-timezone", function() {
        updateEditors();
      });


  };

  /*
  {
    id: unique ID (string)
    jElem: jquery object, where to push the editor in the DOM (object)
    crons: fill CRON lines (array)
  }

  Example:

  // Initialiyze CRONs editors
  stent.cronEditor.init(
    {
      id: "campaign-schedule",
      jElem: $("#campaign-schedule-wrapper"),
      data: (item.schedule && item.schedule.slots && item.schedule.slots.length > 0) ? item.schedule.slots : [],
      timezone: "Etc/GMT+12"
    }
  );

  */
  const readOnly = function (editorId) {

    if (editors[editorId]) {
      editors[editorId].readOnly = true;
      $("#" + editorId + "-timezone").prop("disabled", true);
      $("#" + editorId + " .add-new").remove();
      $("#" + editorId + " .campaign-slot-delete").css("opacity", 0.5);
      $("#" + editorId + " .campaign-slot-delete").css("pointer-events", "none");
      $("#" + editorId + " select").prop("disabled", true);
      $("#" + editorId + " .campaign-slot-duration").prop("disabled", true);
      $("#" + editorId + " .campaign-slot-duration").removeAttr("style");
    }

  };

  const init = function (config) {

    if (!config) {
      console.log("Error when trying to create a new CRON editor");
      return;
    } else {

      editors[config.id] = config;
      editors[config.id].crons = [];
      editors[config.id].jElem.append(editorDOM(config.id));

      if (config.defaultDuration) {
        defaultDuration = config.defaultDuration;
      }

      let editor = editors[config.id];

      // Set the timezone if necessary
      if (editor.timezone && editor.timezone !== "") {
        $("#" + editor.id + "-timezone option[value='" + editor.timezone + "']").prop("selected", true);
      }

      // Create the lines CRON if asked
      if (editor.data && editor.data.length > 0) {

        let slotDom;

        editor.data.forEach(function(slot) {
          if (slot) {
            let cronObject = {
              id: stent.utils.guid(),
              cron: slot.cron,
              duration: slot.duration
            };
            editor.crons.push(cronObject);
            slotDom += cronLineDOM(cronObject);
          }
        });

        delete editor.data;

        $("#" + editor.id + " .campaign-add-schedule")
          .closest("tr")
          .before(slotDom);

        try {
          for (let i = 0; i < editor.crons.length; i++) {
            editor.crons[i].jCron = $("#" + editor.crons[i].id + " .campaign-slot-cron-picker").cron({
              initial: editor.crons[i].cron
            });
            $("#" + editor.crons[i].id + " .campaign-slot-duration").timeDurationPicker(durationPickerSetup(editor.crons[i].duration));
          }

        } catch (err) {
          console.log(err);
        }

      }

      stent.utils.log(editor);

    }

    bindEvents();
  };


  return {
    init,
    getAll,
    get,
    editors,
    readOnly
  };

})();
stent.konsole = function () {

  const labelStyleInfo = "background: #7cb5e5; color: #000000;";
  const labelStyleSuccess = "background: #BADA55; color: #222222;";
  const labelStyleError = "background: #FF0000; color: #FFFFFF;";
  const labelTitle =
  "font-size: 0.8rem; font-weight: lighter; background: #3E3E3E; color: #FFFFFF; padding: 3px 6px;";

  return {
    log: ({url, method, data, response}) => {
      if (typeof url !== "undefined") {
        console.log("%c url      => ", labelStyleInfo, url);
      }

      if (typeof method !== "undefined") {
        console.log("%c method   => ", labelStyleInfo, method);
      }

      if (typeof data !== "undefined") {
        console.log("%c data     => ", labelStyleInfo, data);
      }

      if (typeof response !== "undefined") {
        console.log("%c response => ", labelStyleSuccess, response);
      }
    },

    error: ({url, method, response}) => {
      if (typeof url !== "undefined") {
        console.log("%c url      => ", labelStyleInfo, url);
      }

      if (typeof method !== "undefined") {
        console.log("%c method   => ", labelStyleInfo, method);
      }

      if (typeof response !== "undefined") {
        console.log("%c response => ", labelStyleError, response);
      }
    },

    group: functionName => {
      console.group("%c 👉 " + functionName, labelTitle);
    },

    endGroup: () => {
      console.groupEnd();
    }
  };

}();


